import gql from 'graphql-tag';

export default gql`
query cfGetAllFieldDefiend($type: Int) {
  cfGetAllFieldDefiend(type: $type) {
    data {
      group_name
      fields {
        name
        source_data
      }
    }
  }
}

`;
