import React, { useMemo } from 'react'
import { Modal, ProgressBar } from "react-bootstrap";
import { useIntl } from 'react-intl';
import query_sc_stores_basic from '../../../../../graphql/query_sc_stores_basic';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const ReloadProductModal = ({ reloadProductData, show, currentInfoStore, onHide }) => {
  const { formatMessage } = useIntl()
  const listMissAttributes = useSelector((state) => state.alert.listMissAttributes || [])
  const { data: dataStore } = useQuery(query_sc_stores_basic, {
    fetchPolicy: 'cache-and-network'
  });

  let stores = useMemo(() => {

    const store = dataStore?.sc_stores?.find((st) => st.id == currentInfoStore?.id);
    const channel = dataStore?.op_connector_channels?.find((st) => st.code == store?.connector_channel_code);
    return {
      logo: channel?.logo_asset_url,
      name: store?.name,
      id: store?.id
    }
  }, [dataStore, currentInfoStore])

  return (
    <Modal
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      onHide={() => onHide()}
    >
      <Modal.Header>
        <Modal.Title>
          {formatMessage({ defaultMessage: 'Tải sản phẩm' })}
        </Modal.Title>
        <span><i style={{ cursor: "pointer" }} onClick={() => {
          onHide()
        }}
          className="drawer-filter-icon fas fa-times icon-md text-right"></i>
        </span>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default" >
        <div className="row">
          <div className="col-12">
            <div className="fs-14 mb-3">{formatMessage({ defaultMessage: 'Gian hàng' })}:
              <span className="ml-2">
                <img
                  src={stores?.logo}
                  style={{ width: 20, height: 20, objectFit: "contain" }}
                />
                <span className="ml-1">{stores?.name}</span>
              </span>
            </div>
            <div className="fs-14 mb-3">
              <ProgressBar style={{ height: '30px', fontSize: '14px' }}
                now={reloadProductData?.progressBar} label={`${reloadProductData?.progressBar}%`} />
            </div>
            <div className='d-flex align-items-center'>
              <span className='text-success'>{reloadProductData?.totalProductSuccess || 0}</span> &nbsp;
              <span>sản phẩm tải thành công</span>
            </div>
            <div className='d-flex align-items-center'>
              <span className='text-primary'>{reloadProductData?.totalProductFailures || 0}</span>&nbsp;
              <span>sản phẩm tải thất bại</span>&nbsp;
            </div>
            {!!reloadProductData?.totalProductFailures && <div style={{ fontStyle: "italic" }}>
              (Do gian hàng&nbsp;
              <span>{stores?.name}</span>&nbsp;
              có&nbsp;
              {listMissAttributes?.find(_ => _?.store?.id == stores?.id)?.list_category?.length}&nbsp;
              danh mục thiếu thuộc tính,&nbsp;
              <span
                className='text-primary'
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  window.open("/setting/channel/" + stores?.id, "_blank")
                }}
              > {formatMessage({ defaultMessage: "bấm vào đây" })}</span> &nbsp;
              để tải lại thuộc tính cho danh mục)
            </div>}
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer style={{ padding: '5px' }} className="form" >
        <button
          type="button"
          className="btn btn-secondary mr-3"
          style={{ width: 100 }}
          onClick={() => onHide()}
        >
          {formatMessage({ defaultMessage: 'Đóng' })}
        </button>
      </Modal.Footer>
    </Modal >
  )
}

export default ReloadProductModal