import React, { useMemo } from 'react'
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import SVG from "react-inlinesvg";
import { useHistory, useLocation, useParams} from "react-router-dom";
import queryString from 'querystring'
import TableKiotVietProduct from './TableKiotVietProduct';
import TableSettingKiotViet from './TableSettingKiotViet';
import TableWarehouseManagement from './TableWarehouseManagement';

const KiotVietPage = ({currentBrands}) => {
  const {formatMessage} = useIntl()
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search.slice(1, 100000));

    console.log(params?.tab)
  return (
    <>
        {(params?.tab == 'synced_info' || !params?.tab) && <>
            <TableKiotVietProduct currentBrands={currentBrands} system_code={params?.system_code}/>
        </>}
        {params?.tab == 'setting' && <TableSettingKiotViet />}
        {params?.tab == 'warehouse_management' && <TableWarehouseManagement />}
    </>
  )
}

export default KiotVietPage