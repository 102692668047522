import { useIntl } from 'react-intl';
import RcTable from 'rc-table';
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import Pagination from '../../../../../components/Pagination';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { useToasts } from 'react-toast-notifications';
import dayjs from 'dayjs';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "querystring";
import { SEARCH_OPTIONS, SUBTAB, SUBTAB_KIOTVIET } from '../Constants';
import query_userGetListProductSyncFullfillment from '../../../../../graphql/query_userGetListProductSyncFullfillment'
import { Checkbox } from '../../../../../_metronic/_partials/controls';
import client from '../../../../../apollo'
import query_sme_catalog_product_variant from "../../../../../graphql/query_sme_catalog_product_variant";
import query_userCountProductSyncFullfillment from '../../../../../graphql/query_userCountProductSyncFullfillment'
import mutate_userSyncInventoryFullfillment from '../../../../../graphql/mutate_userSyncInventoryFullfillment'
import InfoProduct from '../../../../../components/InfoProduct';
import HoverImage from '../../../../../components/HoverImage';
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { flatten } from 'lodash';
import LoadingDialog from '../../../FrameImage/LoadingDialog';
import Select from 'react-select';
import { omit } from 'lodash';
import { ModalInventoryStock } from './ModalInventoryStock';
import mutate_linkProductFromProvider from '../../../../../graphql/mutate_linkProductFromProvider';
import ModalResult from './ModalResult';
import ModalCreate from './ModalCreate';
import mutate_userCreateProductFromProvider from '../../../../../graphql/mutate_userCreateProductFromProvider';
import query_sme_brands from '../../../../../graphql/query_sme_brands';
import ModalUploadFile from './ModalUploadFile';
import mutate_userSyncProductFullfillment from '../../../../../graphql/mutate_userSyncProductFullfillment';
import AuthorizationWrapper from '../../../../../components/AuthorizationWrapper';
import ModalConnect from './ModalConnect';
import ModalUploadFileConnect from './ModalUploadFileConnect';
import { HistoryRounded } from '@material-ui/icons';
import query_userGetListProductSyncFulfillmentKiotViet from '../../../../../graphql/query_userGetListProductSyncFulfillmentKiotViet';
import mutate_unlinkProductFromProvider from '../../../../../graphql/mutate_unlinkProductFromProvider';
import ModalConfirmUnlink from './ModalConfirmUnlink';

const TableKiotVietProduct = ({currentBrands, system_code}) => {
    const location = useLocation();
    const history = useHistory();
    const { addToast } = useToasts()
    const params = queryString.parse(location.search.slice(1, 100000));
    const { formatMessage } = useIntl()
    const [ids, setIds] = useState([])
    const [showInventory, setShowInventory] = useState(false)
    const [itemInventory, setItemInventory] = useState(null)
    const [search, setSearch] = useState(params?.q || '')
    const [productVariants, setProductVarians] = useState([])
    const [dataResult, setDataResult] = useState(null)
    const [showCreate, setShowCreate] = useState(false)
    const [showConnect, setShowConnect] = useState({show: false, item: null})
    const [showConFirm, setShowConfirm] = useState({show: false, item: null})
    const [showUploadFile, setShowUploadFile] = useState(false)
    const [showUploadFileConnect, setShowUploadFileConnect] = useState(false)
    let { id } = useParams();

    const page = useMemo(() => {
        try {
            let _page = Number(params.page);
            if (!Number.isNaN(_page)) {
                return Math.max(1, _page)
            } else {
                return 1
            }
        } catch (error) {
            return 1;
        }
    }, [params.page]);

    const pageSize = useMemo(() => {
        try {
            let _value = Number(params.limit)
            if (!Number.isNaN(_value)) {
                return Math.max(25, _value)
            } else {
                return 25
            }
        } catch (error) {
            return 25
        }
    }, [params.limit]);

    const searchText = useMemo(() => {
        if (params?.q) {
            return { searchText: params.q }
        }
        return {}
    }, [params.q]);

    const type = useMemo(() => {
        if (system_code == 'kiotviet') {
            return { type: params.status || 'linked' }
        }
        return { type: params.status || 'synced_success' }
    }, [params.status, system_code]);

    const status = useMemo(() => {
        if (params.sync_status) {
            return { status: params.sync_status }
        }
        return {}
    }, [params.sync_status])


    let whereConditions = useMemo(() => {
        return {
            page,
            pageSize,
            ...searchText,
            ...type,
            connectedProviderId: params?.id,
            ...(system_code == 'vietful' ? {smeBrandId: +currentBrands?.value} : {}),
            ...(system_code == 'kiotviet' ? status : {})
        }

    }, [page, pageSize, searchText, params?.id, type, system_code, currentBrands, status])

    const queryGetProductVariants = async (ids) => {
        if (ids?.length == 0) return [];

        const { data } = await client.query({
            query: query_sme_catalog_product_variant,
            variables: {
                where: {
                    id: { _in: ids },
                },
            },
            fetchPolicy: "network-only",
        });

        return data?.sme_catalog_product_variant || [];
    }

    const [userSyncInventoryFullfillment, {loading: loadingUserSyncInventoryFullfillment}] = useMutation(mutate_userSyncInventoryFullfillment, {
        awaitRefetchQueries: true,
        refetchQueries: ['userGetListProductSyncFullfillment', 'userCountProductSyncFullfillment', 'userGetListProductSyncFulfillmentKiotViet']
    })

    const [userSyncProductFullfillment, {loading:loadingUserSyncProductFullfillment}] = useMutation(mutate_userSyncProductFullfillment, {
        awaitRefetchQueries: true,
        refetchQueries: ['userGetListProductSyncFullfillment', 'userCountProductSyncFullfillment', 'userGetListProductSyncFulfillmentKiotViet']
    })

    const [linkProductFromProvider, {loading: loadingLinkProduct}] = useMutation(mutate_linkProductFromProvider, {
        awaitRefetchQueries: true,
        refetchQueries: ['userGetListProductSyncFullfillment', 'userCountProductSyncFullfillment', 'userGetListProductSyncFulfillmentKiotViet']
    })

    const [unlinkProductFromProvider, {loading: loadingUnLinkProduct}] = useMutation(mutate_unlinkProductFromProvider, {
        awaitRefetchQueries: true,
        refetchQueries: ['userGetListProductSyncFullfillment', 'userGetListProductSyncFulfillmentKiotViet', 'userCountProductSyncFullfillment']
    })

    const [createProduct, {loading: loadingCreateProduct}] = useMutation(mutate_userCreateProductFromProvider, {
        awaitRefetchQueries: true,
        refetchQueries: ['userGetListProductSyncFullfillment', 'userCountProductSyncFullfillment', 'userGetListProductSyncFulfillmentKiotViet']
    })

    const {data: dataBrand} = useQuery(query_sme_brands, {
        fetchPolicy: 'cache-and-network'
    })

    const userGetListProductSyncFullfillmentQuery = useMemo(() => {
        return params?.status == 'not_linked' ? query_userGetListProductSyncFullfillment : query_userGetListProductSyncFulfillmentKiotViet
    }, [params.status])

    const { loading: loadingListProductSyncFullfillment, error, data: dataListProductSyncFullfillment, refetch } = useQuery(userGetListProductSyncFullfillmentQuery, {
        fetchPolicy: "cache-and-network",
        variables: {
            ...whereConditions
        }
    });

    const { loading: loadingCount, data: countProductSyncFullfillment } = useQuery(query_userCountProductSyncFullfillment, {
        fetchPolicy: "cache-and-network",
        variables: {
            ...searchText,
            connectedProviderId: params?.id,
            ...(system_code == 'vietful' ? {smeBrandId: +currentBrands?.value} : {}),
        }
    });

    const countProductConnect = (status) => {
        const { countNotLinked, countLinked } = countProductSyncFullfillment?.userCountProductSyncFullfillment ?? {}

        if (status == 'not_linked') {
            return countNotLinked
        }
        return countLinked
    }

    const brandDefault = useMemo(() => {
        if (!dataBrand?.sme_brands) return {}
        return dataBrand?.sme_brands?.find(brand => brand?.is_default)
    }, [dataBrand])

    useMemo(async () => {
        try {
            const listData = params?.status == 'not_linked' ? dataListProductSyncFullfillment?.userGetListProductSyncFullfillment?.items?.map(item => item?.variant_id) 
            : dataListProductSyncFullfillment?.userGetListProductSyncFulfillmentKiotViet?.items?.map(item => item?.variant_id) 
            const productVariants = await queryGetProductVariants(listData?.length ? listData : [])
            const data = productVariants?.map(variant => ({
                id: variant?.id,
                image: variant?.sme_catalog_product_variant_assets?.[0]?.asset_url,
                is_combo: variant?.is_combo,
                variant_full_name: variant?.variant_full_name,
                sme_catalog_product_id: variant?.sme_catalog_product?.id,
                provider_info: variant?.provider_links?.find(item => item?.provider_connected_id == params?.id),
                unit: variant?.unit,
                created_at: variant?.created_at,
            }))
            setProductVarians(data)
        } catch (err) {

        }
    }, [dataListProductSyncFullfillment])


    const dataTable = useMemo(() => {
        if (params?.status == 'not_linked') {
            const data = dataListProductSyncFullfillment?.userGetListProductSyncFullfillment?.items?.map(item => {
                if (item?.errorMessage) {
                    return [
                        {
                            sku: item?.sku,
                            name: item?.name,
                            stockActual: item?.stockActual,
                            stockAvailable: item?.stockAvailable,
                            stockReserve: item?.stockReserve,
                            synced: item?.synced,
                            syncedDate: item?.syncedDate,
                            updatedDate: item?.updatedDate,
                            inventoryUpdateDate: item?.inventoryUpdateDate,
                            variant_id: item?.variant_id,
                            error: item?.errorMessage,
                            rowSpan: 1
                        }, { errorMessage: item?.errorMessage,
                            rowSpan: 1
                         }]
                }
                return {
                    sku: item?.sku,
                    name: item?.name,
                    stockActual: item?.stockActual,
                    stockAvailable: item?.stockAvailable,
                    stockReserve: item?.stockReserve,
                    synced: item?.synced,
                    syncedDate: item?.syncedDate,
                    updatedDate: item?.updatedDate,
                    inventoryUpdateDate: item?.inventoryUpdateDate,
                    variant_id: item?.variant_id,
                    error: item?.errorMessage,
                    rowSpan: 1
                }
            })
            return flatten(data)
        }
        const data = dataListProductSyncFullfillment?.userGetListProductSyncFulfillmentKiotViet?.items?.map(item => {
            if (item?.product_links?.length > 1) {
                return flatten(item?.product_links?.map((productLink, index) => {
                    if (index == 0) {
                        if (productLink?.sync_error_msg) {
                            return [
                                {
                                    ...productLink,
                                    sku: item?.sku,
                                    name: item?.name,
                                    variant_id: item?.variant_id,
                                    rowSpan: item?.product_links?.length + item?.product_links?.filter(item => item?.sync_error_msg).length,
                                    error: productLink?.sync_error_msg,
                                    updatedDate: productLink?.product_updated_at,
                                    inventoryUpdateDate: productLink?.inventory_updated_at,
                                }, { errorMessage: productLink?.sync_error_msg,
                                    rowSpan: item?.product_links?.length
                                }]
                        }
                        return {
                            ...productLink,
                            sku: item?.sku,
                            name: item?.name,
                            variant_id: item?.variant_id,
                            rowSpan: item?.product_links?.length + item?.product_links?.filter(item => item?.sync_error_msg).length,
                            error: productLink?.sync_error_msg,
                            updatedDate: productLink?.product_updated_at,
                            inventoryUpdateDate: productLink?.inventory_updated_at,
                        }
                    } else {
                        if (productLink?.sync_error_msg) {
                            return [
                                {
                                    ...productLink,
                                    name: item?.name,
                                    variant_id: item?.variant_id,
                                    error: productLink?.sync_error_msg,
                                    updatedDate: productLink?.product_updated_at,
                                    inventoryUpdateDate: productLink?.inventory_updated_at,
                                    rowSpan: 0
                                }, { errorMessage: productLink?.sync_error_msg,
                                    rowSpan: item?.product_links?.length
                                }]
                        }
                        return {
                            ...productLink,
                            name: item?.name,
                            variant_id: item?.variant_id,
                            rowSpan: 0,
                            updatedDate: productLink?.product_updated_at,
                            inventoryUpdateDate: productLink?.inventory_updated_at,
                            error: productLink?.sync_error_msg
                        }
                    }
                    
                }))
            }
            if (item?.product_links?.[0]?.sync_error_msg) {
                return [{
                    ...item,
                    ...item?.product_links?.[0],
                    updatedDate: item?.product_links?.[0]?.product_updated_at,
                    inventoryUpdateDate: item?.product_links?.[0]?.inventory_updated_at,
                    error: item?.product_links?.[0]?.sync_error_msg,
                    rowSpan: 1
                }, { errorMessage: item?.product_links?.[0]?.sync_error_msg,
                    rowSpan: 1
                 }]
            } else{
                return {
                    ...item,
                    ...item?.product_links?.[0],
                    updatedDate: item?.product_links?.[0]?.product_updated_at,
                    inventoryUpdateDate: item?.product_links?.[0]?.inventory_updated_at,
                    rowSpan: 1
                }
            }
        })
        return flatten(data)
    }, [dataListProductSyncFullfillment, params?.status])

    console.log(dataTable)
    const toast = (status, msg) => {
        addToast(msg, { appearance: !!status ? 'success' : 'error' })
    }

    const handleSyncInventoryFullfillment = async () => {
        try {
            const { data } = await userSyncInventoryFullfillment({
                variables: {
                    userSyncProductFullfillmentInput: {
                        providerConnectedId: +params?.id,
                        variantIds: ids?.map(it => it?.variant_id)
                    }
                }
            })
            toast(data?.userSyncInventoryFullfillment?.success, data?.userSyncInventoryFullfillment?.message)
            setIds([])
        } catch (err) {

        }
    }

    const handleSyncProductFullfillment = async () => {
        const { data } = await userSyncProductFullfillment({
            variables: {
                userSyncProductFullfillmentInput: {
                    providerConnectedId: +params?.id,
                    variantIds: ids?.map(it => it?.variant_id),
                    sourceSkuPartner: 1
                }
            }
        })
        toast(data?.userSyncProductFullfillment?.success, data?.userSyncProductFullfillment?.message)
        setIds([])
    }


    const viewAction = useMemo(() => {
        return params?.status != 'not_linked' ?<Dropdown drop='down'>
            <Dropdown.Toggle disabled={!ids.length} className={`${ids?.length ? 'btn-primary' : 'btn-darkk'} btn`} >
                {formatMessage({ defaultMessage: "Thao tác hàng loạt" })}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <>
                    <Dropdown.Item className="mb-1 d-flex" onClick={handleSyncInventoryFullfillment} >
                        {formatMessage({ defaultMessage: "Đồng bộ thông tin tồn" })}
                    </Dropdown.Item>
                </>
            </Dropdown.Menu>
        </Dropdown>
        : <button onClick={async () => {
            let {data} = await linkProductFromProvider({
                variables: {
                    provider_connected_id: +params?.id,
                    data: ids?.map(it => ({
                        provider_skus: [it?.sku],
                        variant_id: it?.variant_id
                    }))
                }
            })
            if (data?.linkProductFromProvider?.success) {
                setDataResult({
                    isProvider: false,
                    total: ids?.length,
                    totalError: data?.linkProductFromProvider?.errors?.length,
                    errors: data?.linkProductFromProvider?.errors
                })
            } else {
                addToast(data?.linkProductFromProvider?.message || 'Liên kết thất bại', {appearance: 'error'})
            }
        }} type="button" disabled={!ids.length} className="btn btn-primary mr-3 px-8" style={{ width: 'max-content', background: ids?.length == 0 ? "#6c757d" : "", border: ids?.length == 0 ? "#6c757d" : "" }}>
            {formatMessage({ defaultMessage: 'Liên kết hàng loạt' })}
        </button>

    }, [ids, params.status])

    const errorView = () => {
        return (
            <div
                className="w-100 text-center mt-8r"
                style={{ position: "absolute", zIndex: 100, left: '50%', transform: 'translateX(-50%)' }}
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <i
                        className="far fa-times-circle text-danger"
                        style={{ fontSize: 48, marginBottom: 8 }}
                    ></i>
                    <p className="mb-6">{formatMessage({ defaultMessage: 'Xảy ra lỗi trong quá trình tải dữ liệu' })}</p>
                    <button
                        className="btn btn-primary btn-elevate"
                        style={{ width: 100 }}
                        onClick={(e) => {
                            e.preventDefault();
                            refetch();
                        }}
                    >
                        {formatMessage({ defaultMessage: 'Tải lại' })}
                    </button>
                </div>
            </div>
        )
    }

    const isSelectAll = ids.length > 0 && ids.filter((x) => dataTable?.filter(x => !x?.errorMessage && !!x?.sku)?.some((item) => item.variant_id === x.variant_id))?.length == dataTable?.filter(x => !x?.errorMessage && !!x?.sku)?.length;

    const columns = [
        params?.status == 'not_linked' && {
            title: <div className="d-flex align-items-center">
                <Checkbox
                    inputProps={{
                        'aria-label': 'checkbox',
                    }}
                    isSelected={isSelectAll}
                    onChange={(e) => {
                        if (isSelectAll) {
                            setIds(ids.filter((x) => !dataTable?.filter(x => !x?.errorMessage)?.some((item) => item.variant_id === x.variant_id)));
                        } else {
                            const tempArray = [...ids];
                            (dataTable?.filter(x => !x?.errorMessage) || []).forEach((product) => {
                                if (product && !ids.some((item) => item.variant_id === product.variant_id)) {
                                    tempArray.push(product);
                                }
                            });
                            setIds(tempArray);
                        }
                    }}
                />
                <span className="ml-2">SKU</span>
            </div>,
            align: 'left',
            width: 200,
            className: 'p-0',
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: 9 };
                }
            },
            render: (record, item) => {
                if (item?.errorMessage) {
                    return <div style={{ padding: '7px', color: '#F80D0D', background: 'rgba(254, 86, 41, 0.31)' }}>{item?.errorMessage}</div>
                }
                const productVariant = productVariants?.find(variant => variant?.id == item?.variant_id)
                return (
                    <div style={{ padding: '7px' }} className='d-flex align-items-center'>
                        <Checkbox inputProps={{ 'aria-label': 'checkbox', }} isSelected={ids.some((_id) => _id.variant_id == item.variant_id)}
                            onChange={(e) => {
                                if (ids.some((_id) => _id.variant_id == item.variant_id)) {
                                    setIds((prev) =>
                                        prev.filter((_id) => _id.variant_id != item.variant_id)
                                    );
                                } else {
                                    setIds((prev) => prev.concat([item]));
                                }
                            }}
                        />

                        <div style={{ cursor: 'pointer' }} onClick={() => window.open(!!productVariant?.is_combo ? `/products/edit-combo/${productVariant?.sme_catalog_product_id}` : `/products/edit/${productVariant?.sme_catalog_product_id}`, '_blank')} className='d-flex ml-2'>
                            <InfoProduct sku={item?.sku} isSingle />
                        </div>
                    </div>
                )
            }
        },
        params?.status != 'not_linked' && {
            title: <div className="d-flex align-items-center">
                <Checkbox
                    inputProps={{
                        'aria-label': 'checkbox',
                    }}
                    isSelected={isSelectAll}
                    onChange={(e) => {
                        if (isSelectAll) {
                            setIds(ids.filter((x) => !dataTable?.filter(x => !x?.errorMessage && !!x?.sku)?.some((item) => item.variant_id === x.variant_id)));
                        } else {
                            const tempArray = [...ids];
                            (dataTable?.filter(x => !x?.errorMessage && !!x?.sku) || []).forEach((product) => {
                                if (product && !ids.some((item) => item.variant_id === product.variant_id)) {
                                    tempArray.push(product);
                                }
                            });
                            setIds(tempArray);
                        }
                    }}
                />
                <span className="ml-2">SKU</span>
            </div>,
            align: 'left',
            width: 200,
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: record?.rowSpan > 1 ? 0 : 9 };
                    // return { colSpan: 0 };
                }
            },
            className: 'p-0',
            render: (value, record, index) => {
                const rowSpan = record?.rowSpan;
                const productVariant = productVariants?.find(variant => variant?.id == record?.variant_id);
                if (record?.errorMessage) {
                    return <div style={{ padding: '7px', color: '#F80D0D', background: 'rgba(254, 86, 41, 0.31)' }}>{record?.errorMessage}</div>
                }
                return {
                    children: (
                        <div className='d-flex justify-content-center' style={{flexDirection: 'column'}}>
                            <div style={{ padding: '7px' }} className='d-flex align-items-center'>
                                <Checkbox inputProps={{ 'aria-label': 'checkbox', }} isSelected={ids.some((_id) => _id.variant_id == record.variant_id)}
                                    onChange={(e) => {
                                        if (ids.some((_id) => _id.variant_id == record.variant_id)) {
                                            setIds((prev) =>
                                                prev.filter((_id) => _id.variant_id != record.variant_id)
                                            );
                                        } else {
                                            setIds((prev) => prev.concat([record]));
                                        }
                                    }}
                                />
                                <div style={{ cursor: 'pointer' }} onClick={() => window.open(!!productVariant?.is_combo ? `/products/edit-combo/${productVariant?.sme_catalog_product_id}` : `/products/edit/${productVariant?.sme_catalog_product_id}`, '_blank')} className='d-flex ml-2'>
                                    <InfoProduct sku={record?.sku} isSingle />
                                </div>
                            </div>
                            <span className='text-primary cursor-pointer ml-2' style={{fontSize: 12, fontWeight: 600}} onClick={() => {
                                setShowConnect({
                                    show: true,
                                    item: record
                                })
                            }}>
                                {formatMessage({ defaultMessage: 'Thêm liên kết'})}
                            </span>
                        </div>
                    ),
                    props: { rowSpan }
                };
            }
        },
        {
            title: formatMessage({ defaultMessage: 'Tên hàng hóa' }),
            align: 'left',
            width: 200,
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: 0 };
                }
            },
            render: (value, record, index) => {
                const productVariant = productVariants?.find(variant => variant?.id == record?.variant_id)
                return {
                    children: (
                        <div>
                            <div className='d-flex align-items-center'>
                                <div style={{ backgroundColor: '#F7F7FA', width: 30, height: 30, borderRadius: 4, overflow: 'hidden', minWidth: 30 }} className='mr-2' >
                                    {!productVariant ? <Skeleton style={{ width: 30, height: 30, borderRadius: 4 }} count={1} />
                                        : <div onClick={() => window.open(!!productVariant?.is_combo ? `/products/edit-combo/${productVariant?.sme_catalog_product_id}` : `/products/edit/${productVariant?.sme_catalog_product_id}`, '_blank')}>
                                            <HoverImage
                                                styles={{ borderRadius: '4px', border: '1px solid #d9d9d9', cursor: 'pointer', marginRight: 10 }}
                                                size={{ width: 320, height: 320 }}
                                                defaultSize={{ width: 30, height: 30 }}
                                                url={productVariant?.image || ''} />
                                        </div>
                                    }
                                </div>
                                <div>
                                    <div className='d-flex'>
                                        {!productVariant?.variant_full_name ? <Skeleton style={{ width: 250, height: 30, borderRadius: 4 }} count={1} /> : (
                                            <InfoProduct url={!!productVariant?.is_combo ? `/products/edit-combo/${productVariant?.sme_catalog_product_id}` : `/products/edit/${productVariant?.sme_catalog_product_id}`} name={productVariant?.variant_full_name} isSingle />
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    props: { rowSpan: record?.rowSpan }
                }
            }
        },
        (params?.status == 'linked' || !params?.status) && {
            title: formatMessage({ defaultMessage: 'SKU liên kết' }),
            align: 'left',
            width: 250,
            className: 'p-0',
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: record?.rowSpan > 1 ? 2  : 0};
                    // return { colSpan: 2};
                }
            },
            render: (record, item) => {
                if (record?.errorMessage) {
                    return <div style={{ padding: '7px', color: '#F80D0D', background: 'rgba(254, 86, 41, 0.31)' }}>{record?.errorMessage}</div>
                }
                const chooseItem = dataTable?.find(it => it?.variant_id == item?.variant_id && !!it?.sku)
                return (
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='p-2'>{item?.provider_sku || '--'}</span>
                        <span className='text-primary cursor-pointer mr-2'style={{fontSize: 12}} onClick={() => {
                            setShowConfirm({
                                show: true,
                                item: item
                            })
                        }}>{formatMessage({defaultMessage: 'Hủy liên kết'})}</span>
                    </div>
                )
            }
        },
        ((params?.status == 'linked' || !params?.status) && {
            title: formatMessage({ defaultMessage: 'Trạng thái đồng bộ' }),
            align: 'center',
            width: 160,
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: 0 };
                }
            },
            render: (record, item) => {
                if (item?.errorMessage) {
                    return <div></div>
                }
                const viewStatus = () => {
                    if (item?.error) {
                        return <div style={{ color: '#FF0000' }}>{formatMessage({ defaultMessage: 'Đồng bộ lỗi' })}</div>
                    }
                    return <div style={{ color: '#3DA153' }}>{formatMessage({ defaultMessage: 'Đồng bộ thành công' })}</div>
                }
                return (
                    <div>
                        {viewStatus()}
                    </div>
                )
            }
        }),
        ((params?.status == 'linked' || !params?.status) && {
            title: formatMessage({ defaultMessage: 'Tồn cập nhật' }),
            align: 'center',
            width: 150,
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: 0 };
                }
            },
            render: (value, record, index) => {
                if (record?.errorMessage) {
                    return <div></div>
                }
                return {
                    children: (
                        <span className='text-primary cursor-pointer' onClick={() => {
                            setShowInventory(true)
                            setItemInventory(record)
                        }}>{formatMessage({defaultMessage: 'Xem chi tiết'})}</span>
                    ),
                    props: { rowSpan: record?.rowSpan }
                }
            }   
        }),
        ((params?.status == 'linked' || !params?.status) && {
            title: formatMessage({ defaultMessage: 'Thời gian đồng bộ HH' }),
            align: 'center',
            width: 160,
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: record?.rowSpan > 1 ? 2 : 0};
                }
            },
            render: (record, item) => {
                if (item?.errorMessage) {
                    return <div></div>
                }
                return (
                    <div>
                        {item?.updatedDate ? dayjs(item?.updatedDate).format("HH:mm DD/MM/YYYY") : '--'}
                    </div>
                )
            }
        }),
        ((params?.status == 'linked' || !params?.status) &&{
            title: formatMessage({ defaultMessage: 'Cập nhật tồn gần nhất' }),
            align: 'center',
            width: 160,
            onCell(record, index) {
                if (record?.errorMessage) {
                    return { colSpan: 0 };
                }
            },
            render: (record, item) => {
                if (item?.errorMessage) {
                    return <div></div>
                }
                return (
                    <div>
                        {item?.inventoryUpdateDate ? dayjs(item?.inventoryUpdateDate).format("HH:mm DD/MM/YYYY") : '--'}
                    </div>
                )
            }
        }),
        (params?.status == 'not_linked' && 
            {
                title: formatMessage({ defaultMessage: 'Thời gian tạo trên UpS' }),
                align: 'center',
                width: 120,
                render: (record, item) => {
                    const productVariant = productVariants?.find(variant => variant?.id == item?.variant_id)
                    return (
                        <div>
                            {productVariant?.created_at ? dayjs(productVariant?.created_at).format("HH:mm DD/MM/YYYY") : '--'}
                        </div>
                    )
                }
            }
        ),
        (params?.status == 'not_linked' && 
            {
                title: formatMessage({ defaultMessage: 'Thao tác' }),
                align: 'center',
                width: 100,
                render: (record, item) => {
                    return (
                        <AuthorizationWrapper keys={['setting_third_party_action']}>
                            <span className='text-primary cursor-pointer' onClick={() => {
                                setShowConnect({
                                    show: true,
                                    item: item
                                })
                            }}>{formatMessage({defaultMessage: 'Liên kết'})}</span>
                        </AuthorizationWrapper>
                    )
                }
            }
        )
    ]

    let totalRecord = (params?.status == 'not_linked' ? +dataListProductSyncFullfillment?.userGetListProductSyncFullfillment?.itemCount : +dataListProductSyncFullfillment?.userGetListProductSyncFulfillmentKiotViet?.itemCount) || 0
    let totalPage = Math.ceil(totalRecord / pageSize)

    return (
        <Fragment>
            {showConnect?.show && <ModalConnect onHide={() => setShowConnect({show: false, item: null})} onSubmit={async (skus) => {
                let {data} = await linkProductFromProvider({
                    variables: {
                        provider_connected_id: +params?.id,
                        data: [{
                            provider_skus: skus?.split(',')?.map(item => item?.trim()),
                            variant_id: showConnect?.item?.variant_id
                        }]
                    }
                })
                if (data?.linkProductFromProvider?.success) {
                    setDataResult({
                        isProvider: true,
                        total: data?.linkProductFromProvider?.total,
                        totalError: data?.linkProductFromProvider?.errors?.length,
                        errors: data?.linkProductFromProvider?.errors
                    })
                } else {
                    addToast(data?.linkProductFromProvider?.message || 'Liên kết thất bại', {appearance: 'error'})
                }
                setShowConnect({show: false, item: null})
            }} />}
            {showConFirm?.show && <ModalConfirmUnlink onHide={() => setShowConfirm({show: false, item: null})} onSubmit={async () => {
                let {data} = await unlinkProductFromProvider({
                    variables: {
                        provider_connected_id: +params?.id,
                        link_ids: [showConFirm?.item?.id]
                    }
                })
                if (data?.unlinkProductFromProvider?.success && data?.unlinkProductFromProvider?.errors?.length == 0) {
                    toast(true, 'Hủy liên kết thành công')
                } else {
                    toast(false, data?.unlinkProductFromProvider?.errors?.[0]?.message || data?.unlinkProductFromProvider?.message || 'Hủy liên kết không thành công')
                }
                setShowConfirm({show: false, item: null})
            }} />}
            <LoadingDialog show={loadingListProductSyncFullfillment || loadingCount || loadingLinkProduct || loadingCreateProduct || loadingUserSyncInventoryFullfillment || loadingUserSyncProductFullfillment || loadingUnLinkProduct} />
            {!!dataResult && <ModalResult result={dataResult} onHide={() => {
                setDataResult(null)
                setIds([])
            }}/>}
            {showCreate && <ModalCreate 
                onHide={() => {
                    setShowCreate(false)
                }}
                onSubmit={async (values) => {
                    const skus = values?.skus?.split(',')?.map(item => item?.trim())
                    try {
                        let {data} = await createProduct({
                            variables: {
                                provider_connected_id: +params?.id,
                                skus: skus,
                                sme_brand_id: brandDefault?.id
                            }
                        })
                        if(data?.userCreateProductFromProvider?.success) {
                            setDataResult({
                                isProvider: false,
                                total: data?.userCreateProductFromProvider?.total,
                                totalError: data?.userCreateProductFromProvider?.errors?.length,
                                errors: data?.userCreateProductFromProvider?.errors
                            })
                        } else {
                            addToast(data?.userCreateProductFromProvider?.message || formatMessage({defaultMessage: 'Tạo sản phẩm sang UpS thất bại'}), {appearance: 'error'})
                        }
                        setShowCreate(false)
                    } catch (error) {
                        console.log(error)
                        addToast(formatMessage({defaultMessage: 'Tạo sản phẩm sang UpS thất bại'}), {appearance: 'error'})
                        setShowCreate(false)
                    }
                }}
            />
            } 
            {!!showUploadFile && <ModalUploadFile 
                brandDefault={brandDefault}
                provider_connected_id={+params?.id}
                onHide={() => setShowUploadFile(false)}
                onShowModalFileUploadResults={(data) => {
                    setDataResult({
                        isProvider: false,
                        total: data?.total,
                        totalError: data?.errors?.length,
                        errors: data?.errors
                    })
                }}
            />}
            {!!showUploadFileConnect && <ModalUploadFileConnect
                brandDefault={brandDefault}
                provider_connected_id={+params?.id}
                onHide={() => setShowUploadFileConnect(false)}
            />}
            {showInventory && <ModalInventoryStock 
                show={showInventory} 
                onHide={() => {
                    setShowInventory(false)
                    setItemInventory(null)
                }} 
                item={itemInventory}
            />}
            <div>
                {(params?.tab == 'synced_info' || !params?.tab) && <div className='d-flex justify-content-between align-items-center'>
                    <div className="input-icon pl-0 col-5" style={{ height: "fit-content", width: '100%', position: 'relative' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={formatMessage({ defaultMessage: 'Nhập tên hàng hóa' })}
                            style={{ height: 37, borderRadius: 0, paddingLeft: "50px" }}
                            onBlur={(e) => {
                                history.push(`/setting/third-party-connection/${id}?${queryString.stringify({ ...params, page: 1, q: e.target.value })}`);
                            }}
                            value={search || ""}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.keyCode == 13) {
                                    history.push(`/setting/third-party-connection/${id}?${queryString.stringify({ ...params, page: 1, q: e.target.value, })}`);
                                }
                            }}
                        />
                        <span><i className="flaticon2-search-1 icon-md ml-6"></i></span>
                    </div>
                    <div className='col-1'></div>
                    <AuthorizationWrapper keys={['setting_third_party_action']}>
                    <div className='d-flex align-items-center justify-content-end'>
                        <Dropdown drop='down'>
                            <Dropdown.Toggle className={`btn-primary btn`} >
                                {formatMessage({ defaultMessage: "Tạo sản phẩm sang UpS" })}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <>
                                    <Dropdown.Item className="mb-1 d-flex" onClick={() => {
                                        setShowCreate(true)
                                    }} >
                                        {formatMessage({ defaultMessage: "Tạo mới" })}
                                    </Dropdown.Item>
                                    <Dropdown.Item className="mb-1 d-flex" onClick={() => {
                                        setShowUploadFile(true)
                                    }}>
                                        {formatMessage({ defaultMessage: 'Tạo mới theo file' })}
                                    </Dropdown.Item>
                                </>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    </AuthorizationWrapper>

                </div>}
                <div className='row mt-2'>
                    <div className='col-4'>
                        <div className='d-flex align-items-center justify-content-start'>
                            <div className="mr-4 text-primary" style={{ fontSize: 14 }}>{formatMessage({ defaultMessage: 'Đã chọn {length} sản phẩm:' }, { length: ids?.length })}</div>
                            <AuthorizationWrapper keys={['setting_third_party_action']}>{viewAction}</AuthorizationWrapper>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'sticky', top: '45px', zIndex: 94, background: 'white' }}>
                    <div className="d-flex w-100 mt-2" style={{ background: "#fff", zIndex: 1, borderBottom: 'none' }}>
                        <div style={{ flex: 1, ...(params?.status == 'not_linked' ? {display: 'flex', justifyContent: 'space-between'} : {})}}>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {SUBTAB_KIOTVIET.map((_tab, index) => {
                                    const { title, status } = _tab;
                                    const isActive = status == (params?.status || "linked");
                                    return (
                                        <>
                                            <li style={{ cursor: 'pointer' }} key={`tab-order-${index}`} className={`nav-item ${isActive ? "active" : ""}`}>
                                                <span className={`nav-link font-weight-normal ${isActive ? "active" : ""}`}
                                                    style={{ fontSize: "13px" }}
                                                    onClick={() => {
                                                        setIds([])
                                                        history.push(`${location.pathname}?${queryString.stringify(omit({ ...params, page: 1, status },['sync_status']))}`);
                                                    }}
                                                >
                                                    {formatMessage(title)}
                                                    <span className='mx-2'>

                                                        {`(${countProductConnect(status) || '--'})`}
                                                    </span>
                                                </span>
                                            </li>
                                        </>
                                    );
                                })}
                            </ul>
                            {params?.status == 'not_linked' && <div>
                                <AuthorizationWrapper keys={['setting_third_party_action']}>
                                    <button
                                        className="btn btn-outline-primary btn-elevate mr-2"
                                        onClick={e => {
                                            e.preventDefault();
                                            setShowUploadFileConnect(true)
                                        }}
                                    >
                                        {formatMessage({ defaultMessage: 'Thêm liên kết theo file' })}
                                    </button>
                                    <button
                                        className="btn btn-outline-primary btn-elevate mr-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push("/setting/history-connect-provider");
                                        }}
                                    >
                                        <HistoryRounded />
                                    </button>
                                </AuthorizationWrapper>
                            </div>}
                        </div>
                    </div>

                </div>
                {!!error && !loadingListProductSyncFullfillment && errorView()}
                {(params?.status != 'not_linked' && system_code == 'kiotviet') && <div className="my-2 row">
                    <div className='col-3'>
                        <Select
                            className='w-100 custom-select-warehouse'
                            value={SEARCH_OPTIONS.find(option => option.value == params?.sync_status)}
                            isClearable
                            options={SEARCH_OPTIONS}
                            placeholder={formatMessage({ defaultMessage: 'Trạng thái đồng bộ' })}
                            onChange={value => {
                                if (value?.value) {
                                    history.push(`/setting/third-party-connection/${id}?${queryString.stringify({
                                        ...params,
                                        page: 1,
                                        sync_status: value.value
                                    })}`)
                                } else {
                                    history.push(`/setting/third-party-connection/${id}?${queryString.stringify(omit({
                                        ...params,
                                        page: 1,
                                    }, ['sync_status']))}`)
                                }
                            }}
                            formatOptionLabel={(option, labelMeta) => {
                                return <div>{formatMessage(option.label)}</div>
                            }}
                        />
                    </div>
                </div>}
                <RcTable
                    style={loadingListProductSyncFullfillment ? { opacity: 0.4 } : {}}
                    className="upbase-table"
                    columns={columns}
                    data={dataTable || []}
                    emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                        <img src={toAbsoluteUrl("/media/empty.png")} alt="" width={80} />
                        <span className='mt-4'>{formatMessage({ defaultMessage: 'Không có dữ liệu' })}</span>
                    </div>}
                    tableLayout="auto"
                    sticky={{ offsetHeader: 43 }}
                    scroll={{ x: params?.status == 'not_linked' ? 'max-content' : 1800 }}
                />
                {!error && (
                    <Pagination
                        page={page}
                        totalPage={totalPage}
                        loading={loadingListProductSyncFullfillment}
                        limit={pageSize}
                        totalRecord={totalRecord}
                        count={(params?.status == 'not_linked' ? dataListProductSyncFullfillment?.userGetListProductSyncFullfillment?.items?.length : dataListProductSyncFullfillment?.userGetListProductSyncFulfillmentKiotViet?.items?.length)|| 0}
                        basePath={`/setting/third-party-connection/${id}`}
                        emptyTitle=''
                        style={{ zIndex: 1000 }}
                    />
                )}
            </div>
        </Fragment>
    )
}

export default TableKiotVietProduct