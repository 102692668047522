import gql from 'graphql-tag';

export default gql`
    query sme_product_variant_provider_link_by_file_history ($limit: Int, $offset: Int, $where: sme_product_variant_provider_link_by_file_history_bool_exp) {
        sme_product_variant_provider_link_by_file_history(order_by: {updated_at: desc}, limit: $limit, offset: $offset, where: $where) {
            output_url
            status
            success_row
            total_row
            updated_at
            sync_error_msg
            sme_id
            sme_brand_id
            provider_connected_id
            input_url
            id
            created_at
        }
        sme_product_variant_provider_link_by_file_history_aggregate(where: $where) {
            aggregate {
            count
            }
        }
    }

`;
