import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const ModalConfirmUnlink = ({ onHide, onSubmit }) => {
    const { formatMessage } = useIntl();

    return (
        <Modal show={true} aria-labelledby="example-modal-sizes-title-sm" centered
            size="sm"    
            onHide={onHide}
            backdrop={true}
            dialogClassName={"body-dialog-connect"}>
            <Modal.Body className="overlay overlay-block cursor-default">
                <p className="text-center">{formatMessage({defaultMessage: 'SKU trên UpS không còn liên kết và đồng bộ thông tin tồn của SKU hàng hóa này nữa. Bạn có đồng ý tiếp tục?'})}</p>
            </Modal.Body>
            <Modal.Footer className="form" style={{ borderTop: "1px solid #dbdbdb", justifyContent: "center", paddingTop: 10, paddingBottom: 10 }}>
                <div className="form-group">
                    <button type="button" className="btn btn-light btn-elevate mr-3" style={{ width: 100, background: 'gray', color: 'white' }}
                        onClick={() => onHide()}>
                        {formatMessage({ defaultMessage: "Hủy" })}
                    </button>
                    <button type="button" className="btn btn-primary mr-3" style={{ width: 100 }} onClick={onSubmit}>
                        {formatMessage({ defaultMessage: "Đồng ý" })}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmUnlink