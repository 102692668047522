import gql from 'graphql-tag';

export default gql`
    mutation userCreateProductFromProvider(
        $provider_connected_id: Int!
        $skus: [String!]!
        $sme_brand_id: Int!
    ) {
        userCreateProductFromProvider(
            provider_connected_id: $provider_connected_id
            skus: $skus
            sme_brand_id: $sme_brand_id
        ) {
            message
            success
            total
            errors {
                message
                sku
            }
        }
    }
`;
