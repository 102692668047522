import gql from 'graphql-tag';

export default gql`
    mutation linkProductFromProviderByFile($provider_connected_id: Int!, $url: String!, $sme_brand_id: Int!) {
        linkProductFromProviderByFile(
            provider_connected_id: $provider_connected_id
            url: $url
            sme_brand_id: $sme_brand_id
        ) {
            id
            message
            success
        }
    }
`;
