import gql from 'graphql-tag';

export default gql`
    query userCountProductSyncFullfillment(
        $smeBrandId: Int
        $connectedProviderId: Int!
        $searchText: String
        $status: String
    ) {
        userCountProductSyncFullfillment(
            smeBrandId: $smeBrandId
            connectedProviderId: $connectedProviderId
            searchText: $searchText
            status: $status
        ) {
            countNotSynced
            countSyncedFail
            countSyncedSuccess
            countNotLinked
            countLinked
        }
    }
`;
