import gql from 'graphql-tag';

export default gql`
query scGetCategoryMissAttribute {
  scGetCategoryMissAttribute {
    list_data {
      store {
        id
        name
        connector_channel_code
      }
      list_category {
        id
        name
      }
    }
    message
    success
  }
}
`;
