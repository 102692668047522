import { call, put, takeLatest } from "redux-saga/effects";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import client from "../apollo";
import query_scGetCategoryMissAttribute from "../graphql/query_scGetCategoryMissAttribute";

export const actionTypes = {
    MissAttribute: "[MissAttribute] Action",
    SetMissAttribute: "[SetMissAttribute] Action",
};

const initialAlertState = {
    listMissAttributes: []
};

export const reducer = (state = initialAlertState, action) => {
    switch (action.type) {
        case actionTypes.SetMissAttribute: {
            const { listMissAttributes } = action.payload;
            return { ...state, listMissAttributes };
        }
        default:
            return state;
    }
}


export const actions = {
    setAlert: (listMissAttributes) => ({ type: actionTypes.SetMissAttribute, payload: { listMissAttributes } }),
    missAttribute: () => ({ type: actionTypes.MissAttribute, payload: { a: 1 } }),
};

export function* saga() {
    yield takeLatest(actionTypes.MissAttribute, function* getMissAttribute(action) {
        const { data } = yield call(client.query, {
            query: query_scGetCategoryMissAttribute,
            fetchPolicy: "no-cache",
        });

        const list_data = data?.scGetCategoryMissAttribute?.list_data;
        let listAlerts = list_data?.filter(d => !!d?.list_category?.length)?.map((d) => {
            return ({
                ...d,
                store: {
                    ...d?.store,
                    logo: toAbsoluteUrl(`/media/logo_${d?.store?.connector_channel_code}.png`)
                }
            })
        })

        yield put(actions.setAlert(listAlerts))
    });
}