import React, { useMemo, useState } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { StickyToolbar } from "./extras/StickyToolbar";
import StepModal from "../../../components/StepModal";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../app/modules/Auth";
import clsx from "clsx";
import ZaloChatWidget from "../../../components/ZaloWidget";
import ModalExpired from "../../../components/ModalExpired";
import ModalChangePassword from "../../../components/ModalChangePassWord";
import dayjs from 'dayjs';

const isMoreThanSixMonths = (dateString) => {
    const givenDate = dayjs(dateString);
    const now = dayjs();

    const sixMonthsAgo = now.subtract(6, 'months');
    return givenDate.unix() < sixMonthsAgo.unix();
};

export function Layout({ children }) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const uiService = useHtmlClassService();
    const showIntroStep = useSelector((state) => state.auth.showIntroStep);
    const user = useSelector((state) => state.auth.user)
    const [showChangePassword, setShowChangePassword] = useState(false)

    useMemo(() => {
        if (isMoreThanSixMonths(user?.last_change_password_at)) {
            setShowChangePassword(true)
        } else {
            setShowChangePassword(false)
        }
    }, [user])
    // console.log(user)

    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    const [isShowStepIntro, onHideStepIntro] = useMemo(() => {
        return [
            location.pathname != '/user-profile/update-information' && !!showIntroStep,
            () => {
                dispatch(actions.hideIntroStep());
                history.push('/dashboard');
            }
        ]
    }, [location?.pathname, showIntroStep]);

    const isShowAsideMenu = useMemo(() => {
        return location?.pathname != '/frame-image/create-editor' && !location?.pathname?.startsWith('/frame-image/editor');
    }, [location?.pathname]);

    const fromAgency = localStorage.getItem('fromAgency')
    const jwt = localStorage.getItem('jwt')

    return layoutProps.selfLayout !== "blank" ? (
        <>
            {!!fromAgency && !jwt && <ModalExpired />}
            {/*begin::Main*/}
            <HeaderMobile />
            <StepModal show={isShowStepIntro} onHide={onHideStepIntro} />
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {layoutProps.asideDisplay && <Aside isShowAsideMenu={isShowAsideMenu} />}
                    {/*begin::Wrapper*/}
                    <div className={clsx("d-flex flex-column flex-row-fluid wrapper", !isShowAsideMenu && 'layout-aside-hide')} id="kt_wrapper">
                        <Header isShowAsideMenu={isShowAsideMenu} />
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                        >
                            {/* {layoutProps.subheaderDisplay && <SubHeader/>} */}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    <div className={layoutProps.contentContainerClasses}>
                                        {children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && { children }}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer />
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            <QuickUser />
            <QuickPanel />
            <ScrollTop />
            {/* <StickyToolbar/> */}
            {/*end::Main*/}
            <LayoutInit />
            <ZaloChatWidget />
            {showChangePassword && <ModalChangePassword show={showChangePassword} onSubmit={() => {
                history.push('/setting/users/change-password')
                setShowChangePassword(false)
            }} onHide={() => {
                setShowChangePassword(false)
            }} />}
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
