import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import SVG from 'react-inlinesvg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'querystring';
import { Card } from '../../../../../_metronic/_partials/controls';
import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import Table from 'rc-table';
import { useMutation, useQuery } from '@apollo/client';
import query_sme_catalog_stores from '../../../../../graphql/query_sme_catalog_stores';
import query_sc_stores_basic from '../../../../../graphql/query_sc_stores_basic';
import query_sme_store_provider_link from '../../../../../graphql/query_sme_store_provider_link';
import client from '../../../../../apollo';
import LoadingDialog from '../../../FrameImage/LoadingDialog';
import Pagination from '../../../../../components/Pagination';
import ModalConnectStoreWh from './ModalConnectStoreWh';
import mutate_userUpsertSmeStoreProviderLink from '../../../../../graphql/mutate_userUpsertSmeStoreProviderLink';
import { useToasts } from 'react-toast-notifications';
import AuthorizationWrapper from '../../../../../components/AuthorizationWrapper';

const SUB_TABS = [
    {
        key: 'offline_wh',
        tittle: <FormattedMessage defaultMessage="Kho Chi nhánh" />,
    },
    {
        key: 'online_wh',
        tittle: <FormattedMessage defaultMessage="Kho Online" />,
    },
];

const TableWarehouseManagement = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search.slice(1, 100000));
    const { id } = useParams()
    const [dataTable, setDataTable] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [curentWh, setCurrentWh] = useState(null)
    const {addToast} = useToasts()

    const page = useMemo(
                () => {
                    try {
                        let _page = Number(params?.page);
                        if (!Number.isNaN(_page)) {
                            return Math.max(1, _page)
                        } else {
                            return 1
                        }
                    } catch (error) {
                        return 1;
                    }
                }, [params?.page]
            );
        
        const per_page = useMemo(
            () => {
                try {
                    let _value = Number(params?.limit)
                    if (!Number.isNaN(_value)) {
                        return Math.max(100, _value)
                    } else {
                        return 100
                    }
                } catch (error) {
                    return 100
                }
            }, [params?.limit]
        );

    const { data: dataWarehouse, loading: loadingDataWarehouse } = useQuery(
        query_sme_catalog_stores,
        {
            variables: {
                limit: per_page,
                offset: (page - 1) * per_page,
                order_by: {created_at: 'desc'},
                where: {
                    fulfillment_by: {
                        _eq: 3,
                    },
                    status: { _eq: 10 },
                },
            },
            fetchPolicy: 'cache-and-network',
            skip: params?.sub_tab == 'online_wh',
        }
    );

    const { loading: loadingStores, data: dataStores, refetch } = useQuery(query_sc_stores_basic, {
        variables: {
            context: 'order'
        },
        fetchPolicy: "cache-and-network",
        onCompleted: async (data) => {
            if (data?.sc_stores?.length > 0) {
                const { data: dataStoresProviderLink } = await client.query({
                    query: query_sme_store_provider_link,
                    variables: {
                        limit: data?.sc_stores?.length,
                        offset: 0,
                        where: {
                            store_id: {
                                _in: data?.sc_stores?.map(store => store?.id)
                            },
                            fulfillment_provider_connected_id: {
                                _eq: +params?.id
                            }
                        }
                    },
                    fetchPolicy: "network-only",
                });
                const newData = data?.sc_stores?.map(store => {
                    const providerLinked = dataStoresProviderLink?.sme_store_provider_link?.find(wh => wh?.store_id == store?.id) || null
                    return {
                        ...store,
                        ...providerLinked,
                        store_id_clone: store?.id,
                        logo: data?.op_connector_channels?.find(channel => channel?.code == store?.connector_channel_code)?.logo_asset_url,
                        fulfillment_provider_wms_name: providerLinked?.fulfillment_provider_name
                    }
                })
                setDataTable(newData)
            }
        },
        skip: params?.sub_tab != 'online_wh'
    });

    const [userUpsertSmeStoreProviderLink, {loading: loadingUserUpsertSmeStoreProviderLink}] = useMutation(mutate_userUpsertSmeStoreProviderLink, {
        refetchQueries: ['sc_stores', 'op_connector_channels', 'sme_store_provider_link'],
        awaitRefetchQueries: true
    })

    const columns = useMemo(() => {
        return [
            (params?.sub_tab != 'online_wh' ? {
                title: formatMessage({ defaultMessage: 'Tên kho trên UpS' }),
                align: 'left',
                width: '40%',
                dataIndex: 'name',
                key: 'name',
                render: (record, item) => {
                    return <div>{item?.name}</div>;
                },
            } : {
                title: formatMessage({ defaultMessage: 'Gian hàng trên UpS' }),
                align: 'left',
                width: '40%',
                dataIndex: 'name',
                key: 'name',
                render: (record, item) => {
                    return <div>
                        <img src={item?.logo} style={{ width: 20, height: 20, marginRight: 8 }} alt='' />
                        {item?.name}
                    </div>;
                },
            }),
            {
                title: formatMessage({
                    defaultMessage: 'Tên kho trên KiotViet',
                }),
                align: 'left',
                width: '40%',
                dataIndex: 'fulfillment_provider_wms_name',
                key: 'fulfillment_provider_wms_name',
                render: (record, item) => {
                    return <div>{item?.fulfillment_provider_wms_name}</div>;
                },
            },
            {
                title: formatMessage({ defaultMessage: 'Thao tác' }),
                align: 'center',
                width: '20%',
                dataIndex: 'name',
                key: 'name',
                render: (record, item) => {
                    return (
                        <AuthorizationWrapper keys={['setting_third_party_action']} >
                            {params?.sub_tab != 'online_wh' ? <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                    history.push('/products/warehouse-mutate', {
                                        title: formatMessage({ defaultMessage: 'Cập nhật kho' }), isOpen: true,
                                        typeAction: 'UPDATE_WAREHOUSE',
                                        infoWh: {
                                            id: item?.id, name: item?.name,
                                            address: item?.address,
                                            code: item?.code,
                                            inbound_prefix: item?.inbound_prefix,
                                            outbound_prefix: item?.outbound_prefix,
                                            fulfillment_scan_export_mode: item?.fulfillment_scan_export_mode || 2,
                                            fulfillment_scan_pack_mode: item?.fulfillment_scan_pack_mode || 2,
                                            fulfillment_by: item?.fulfillment_by,
                                            fulfillment_provider_wms_code: item?.fulfillment_provider_wms_code,
                                            fulfillment_provider_connected_id: item?.fulfillment_provider_connected_id,
                                            contact_phone: item?.contact_phone,
                                            contact_name: item?.contact_name,
                                            district_code: item?.district_code,
                                            province_code: item?.province_code,
                                            max_mio: item?.max_mio,
                                            max_sio: item?.max_sio,
                                            max_mixio: item?.max_mixio,
                                            ward_code: item?.ward_code,
                                            longtitude: item?.lng,
                                            latitude: item?.lat
                                        }
                                    })
                                }}
                            >
                                {formatMessage({ defaultMessage: 'Xem chi tiết' })}
                            </span> : <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                    setShowModal(true)
                                    setCurrentWh(item)
                                }}
                            >
                                {formatMessage({ defaultMessage: 'Chỉnh sửa' })}
                            </span>}
                    </AuthorizationWrapper>
                    );
                },
            },
        ];
    }, [params?.sub_tab]);

    useMemo(() => {
        if (params?.sub_tab != 'online_wh' && dataWarehouse?.sme_warehouses) {
            setDataTable(dataWarehouse?.sme_warehouses || [])
        }
    }, [params?.sub_tab, dataWarehouse]);

    const totalRecord = dataWarehouse?.sme_warehouses_aggregate?.aggregate?.count || 0;
    const totalPage = Math.ceil(totalRecord / per_page) || 1;

    return (
        <>
            <LoadingDialog show={loadingStores || loadingDataWarehouse || loadingUserUpsertSmeStoreProviderLink} />
            {showModal && <ModalConnectStoreWh 
                store={curentWh} 
                onHide={() => {
                    setShowModal(false)
                    setCurrentWh(null)
                }}
                onSubmit={async (value) => {
                    const {data} = await userUpsertSmeStoreProviderLink({
                        variables: {
                            userUpsertSmeStoreProviderLinkInput: {
                                fulfillment_provider_connected_id: +params?.id,
                                fulfillment_provider_name: value?.name,
                                fulfillment_provider_ref: value?.code,
                                fulfillment_provider_system_code: params?.code,
                                store_id: +curentWh?.store_id_clone
                            }
                        }
                    })
                    if(data?.userUpsertSmeStoreProviderLink?.success) {
                        addToast(formatMessage({defaultMessage: 'Liên kết gian hàng với kho KiotViet thành công'}), {appearance: 'success'})
                        refetch()
                    } else {
                        addToast(data?.userUpsertSmeStoreProviderLink?.message || formatMessage({defaultMessage: 'Liên kết gian hàng với kho KiotViet thất bại'}), {appearance: 'error'})
                    }
                    setShowModal(false)
                    setCurrentWh(null)
                }}/>}
            <ul className="nav nav-tabs-line fs-6">
                {SUB_TABS.map((tab) => {
                    const isActive = params?.sub_tab
                        ? params?.sub_tab === tab.key
                        : tab?.key === 'offline_wh';
                    return (
                        <li
                            key={`report-product-tab-${tab.key}`}
                            className="nav-item"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(
                                    `${
                                        location.pathname
                                    }?${queryString.stringify({
                                        ...params,
                                        page: 1,
                                        sub_tab: tab?.key,
                                    })}`
                                );
                            }}
                        >
                            <a
                                className={clsx('nav-link fs-14', {
                                    active: isActive,
                                })}
                                data-bs-toggle="tab"
                                href=""
                            >
                                {tab.tittle}
                            </a>
                        </li>
                    );
                })}
            </ul>
            <div className="d-flex align-items-center mb-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="text-info bi bi-lightbulb mr-2"
                    viewBox="0 0 16 16"
                >
                    <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                </svg>
                <span className="text-info">
                    {params?.sub_tab != 'online_wh' ? formatMessage({
                        defaultMessage:
                            'Hiển thị các thông tin kho vật lý của UpS liên kết tương ứng với kho bên KiotViet',
                    }) : formatMessage({
                        defaultMessage:
                            'Hiển thị các kho online được liên kết với gian hàng tương ứng trên UpS',
                    })}
                </span>
            </div>
            <div style={{ position: 'relative' }}>
                <Table
                    style={loadingDataWarehouse ? { opacity: 0.4 } : {}}
                    className="upbase-table"
                    columns={columns}
                    data={dataTable || []}
                    emptyText={
                        <div className="d-flex flex-column align-items-center justify-content-center my-10">
                            <img
                                src={toAbsoluteUrl('/media/empty.png')}
                                alt="image"
                                width={80}
                            />
                            <span className="mt-4">
                                {params?.sub_tab != 'online_wh' ? formatMessage({
                                    defaultMessage: 'Chưa có kho Chi nhánh',
                                }) : formatMessage({
                                    defaultMessage: 'Chưa có kho Online',
                                })}
                            </span>
                        </div>
                    }
                    sticky={{ offsetHeader: 45 }}
                />
            </div>
            {params?.sub_tab != 'online_wh' && !!dataTable?.length && <div className="mt-4">
                <Pagination
                    page={page}
                    totalPage={totalPage}
                    loading={loadingDataWarehouse}
                    limit={per_page}
                    totalRecord={totalRecord}
                    count={dataTable?.length}
                    basePath={`/setting/third-party-connection/${id}`}
                    emptyTitle={formatMessage({
                        defaultMessage: 'Không tìm thấy dữ liệu',
                    })}
                    options={[{ label: 100, value: 100 }]}
                />
            </div>}
        </>
    );
};

export default TableWarehouseManagement;
