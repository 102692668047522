import { Field, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { InputVertical } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";

const ModalConnect = ({ onHide, onSubmit }) => {
    const { formatMessage } = useIntl();

    return (
        <Formik
            initialValues={{}}
            validationSchema={Yup.object().shape({})}
        >
            {({ values }) => {
                return <Modal show={true} aria-labelledby="example-modal-sizes-title-sm" centered
                    size="sm"    
                    onHide={onHide}
                    backdrop={true}
                    dialogClassName={"body-dialog-connect"}>
                    <Modal.Body className="overlay overlay-block cursor-default">
                        <Field
                            name={`skus`}
                            component={InputVertical}
                            label={"Nhập các mã SKU cần liên kết"}
                            placeholder={formatMessage({ defaultMessage: 'Nhập các mã SKU cách nhau bởi dấu phẩy' })}
                            required={true}
                        />
                    </Modal.Body>
                    <Modal.Footer className="form" style={{ borderTop: "1px solid #dbdbdb", justifyContent: "center", paddingTop: 10, paddingBottom: 10 }}>
                        <div className="form-group justify-content-end">
                            <button type="button" className="btn btn-light btn-elevate mr-3" style={{ width: 100, background: 'gray', color: 'white' }}
                                onClick={() => onHide()}>
                                {formatMessage({ defaultMessage: "Hủy" })}
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-primary mr-3" 
                                style={{ width: 100 }} 
                                disabled={!values?.skus}
                                onClick={() => onSubmit(values?.skus)}
                            >
                                {formatMessage({ defaultMessage: "Xác nhận" })}
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            }}
        </Formik>
    )
}

export default ModalConnect