import React, { useLayoutEffect, useMemo } from 'react'
import { useSubheader } from '../../../../_metronic/layout';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from "@apollo/client";
import SVG from "react-inlinesvg";
import TableProviderProduct from './TableProviderProduct';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory, useLocation, useParams} from "react-router-dom";
import { Card, CardBody } from '../../../../_metronic/_partials/controls';
import query_sme_brands from '../../../../graphql/query_sme_brands';
import queryString from 'querystring'
import LoadingDialog from "../../FrameImage/LoadingDialog"
import { TAB } from './Constants';
import KiotVietPage from './components/KiotVietPage';
import { omit } from 'lodash';
import query_prvListProvider from '../../../../graphql/query_prvListProvider';

const SettingProvider = () => {
  const { setBreadcrumbs } = useSubheader();
  const {formatMessage} = useIntl()
  let { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search.slice(1, 100000));

  useLayoutEffect(() => {
    setBreadcrumbs([
        {
        title: formatMessage({ defaultMessage: "Cài đặt" }),
        },
        {
        title: formatMessage({ defaultMessage: "Kết nối mở rộng" }),
        },
        {
        title: id
        },
    ]);
  }, []);

  const { data: dataBrand, loading: loadingBrand } = useQuery(query_sme_brands, {
    fetchPolicy: 'cache-and-network'
  });

  const {data: dataProvider, loading: loadingDataProvider} = useQuery(query_prvListProvider, {
    fetchPolicy: 'cache-and-network'
  })

  const listBrand = useMemo(() => {
      if (!dataProvider?.prvListProvider?.data?.length) return [];
      return dataProvider?.prvListProvider?.data?.flatMap((provider) => {
          return (
              provider?.providerConnected?.filter((connected) => !!connected?.sme_brand_id) || []
          );
      });
  }, [dataProvider]);

  const OPTIONS_BRAND = useMemo(() => {
    if(!dataBrand || !dataBrand?.sme_brands?.length) return []
    return listBrand?.map(brand => {
      const currentBrand = dataBrand?.sme_brands?.find(item => brand?.sme_brand_id == item?.id) || {}
      return {
        ...brand,
        value: currentBrand?.id,
        label: currentBrand?.name
      }
    })
  }, [dataBrand, listBrand])

  const currentBrands = useMemo(() => {
    if(params?.system_code != 'vietful') return null
    if(!!params?.brand) {
      return OPTIONS_BRAND?.find(option => option?.value == +params?.brand)
    }
    return OPTIONS_BRAND?.[0]
  }, [OPTIONS_BRAND, params?.brand])

  return (
    <>
      <LoadingDialog show={loadingBrand} />
      <Helmet titleTemplate={formatMessage({ defaultMessage: `Kết nối mở rộng {key}` },{ key: " - UpS" })} 
      defaultTitle={formatMessage({ defaultMessage: `Kết nối mở rộng {key}` },{ key: " - UpS" })}>
        <meta name="description" content={formatMessage({ defaultMessage: `Kết nối mở rộng {key}` },{ key: " - UpS" })}/>
      </Helmet>
      <Card>
        <CardBody>
        {params?.system_code == 'vietful' && <div className="d-flex w-100 mb-4" style={{ zIndex: 1 }}>
          <div style={{ flex: 1 }}>
            <ul className="nav nav-tabs">
              {!loadingBrand && OPTIONS_BRAND.map((tab) => {
                const isTabActive = tab?.value == currentBrands?.value;

                return (
                  <li
                    key={`tab-${tab?.value}`}
                    onClick={() => {
                      history.push(`${location.pathname}?${queryString.stringify({
                        ...params,
                        page: 1,
                        brand: tab?.value,
                        id: tab?.id,
                        system_code:tab?.system_code,
                        code: tab?.code,
                        link_webhook:`${tab?.link_webhook}`,
                        provider_name: tab?.provider_name
                      })}`);
                    }}
                  >
                    <a style={{ fontSize: "16px" }} className={`nav-link ${isTabActive ? "active" : ""}`}>
                      {tab?.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>}
        {params?.system_code == 'kiotviet' && <div className="d-flex w-100 mb-4" style={{ zIndex: 1 }}>
          <div style={{ flex: 1 }}>
            <ul className="nav nav-tabs">
              {TAB.map((tab) => {
                const isTabActive = tab?.key == params?.tab || (!params?.tab && tab?.key == 'synced_info');

                return (
                  <li
                    key={`tab-${tab?.key}`}
                    onClick={() => {
                      history.push(`${location.pathname}?${queryString.stringify(omit({
                        ...params,
                        page: 1,
                        tab: tab?.key
                      }, ['q', 'status', 'sync_status']))}`);
                    }}
                  >
                    <a style={{ fontSize: "16px" }} className={`nav-link ${isTabActive ? "active" : ""}`}>
                      {formatMessage(tab?.title)}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>}
        {params?.system_code != 'kiotviet' &&<TableProviderProduct currentBrands={currentBrands} system_code={params?.system_code}/>}
        {params?.system_code == 'kiotviet' &&<KiotVietPage currentBrands={currentBrands} system_code={params?.system_code}/>}
        </CardBody>
      </Card>
     

      <div
        id="kt_scrolltop1"
        className="scrolltop"
        style={{ bottom: 80 }}
        onClick={() => {
          window.scrollTo({
            letf: 0,
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <span className="svg-icon">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")}
            title={" "}
          ></SVG>
        </span>
      </div>
    </>
  )
}

export default SettingProvider