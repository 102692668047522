import React from "react";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { AssignmentReturned } from "@material-ui/icons";
import { useToasts } from "react-toast-notifications";
import { saveAs } from "file-saver";
import LoadingDialog from "../../ProductsStore/product-new/LoadingDialog";
import { useIntl } from "react-intl";


const RowTable = ({ refetch, data }) => {
  const { addToast } = useToasts();
  const {formatMessage} = useIntl()

  return (
    <>
      {data.map((_item, index) => (
        <tr
          key={index}
          className="borderRight"
          style={{ borderBottom: "1px solid #d9d9d9" }}
        >
            <td style={{ verticalAlign: "top" }} className="pt-4 pb-1">
                <span>{_item?.created_at ? dayjs(_item?.created_at).format("DD/MM/YYYY HH:mm") : "--"}</span>
            
            </td>
            <td>
                {_item.status == 2 ? (
                <span>
                    {formatMessage({defaultMessage:"Lỗi"})}
                </span>
                ) : _item.status == 1 ? (
                <span>
                    {formatMessage({defaultMessage:"Đã xong"})}
                </span>
                ) : (
                "Đang thực hiện liên kết"
                )}
            </td>
            <td style={{ verticalAlign: "top" }} className="pt-4 pb-1">
                {_item?.status == 1 && <><span className={_item?.success_row == _item?.total_row ? 'text-success' : 'text-warning'}>{_item?.success_row}</span>
                    <span>/{_item?.total_row}</span>
                </>}
            </td>
            <td className="text-center">
                {_item?.status == 1 && _item?.success_row < _item?.total_row && <a
                    href={_item?.output_url}
                    className="text-primary"
                >{formatMessage({defaultMessage: 'Tải file lỗi'})}</a>}
            </td>
        </tr>
      ))}
    </>
  );
};

export default RowTable;
