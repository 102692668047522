import clsx from 'clsx';
import React, { memo, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Button, ButtonToolbar, Overlay, OverlayTrigger, Popover } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from "yup";
import { useFormik } from "formik";
import NumberFormat from 'react-number-format';
import { formatNumberToCurrency } from '../../../../../utils';
import AuthorizationWrapper from '../../../../../components/AuthorizationWrapper';

const EditVertical = ({
    text,
    onConfirm,
    show,
    setShow
}) => {
    const { formatMessage } = useIntl();
    const [valueRatio, setValueRatio] = useState(text || '');
    const [error, setError] = useState(null);
    const target = useRef(null);
    const yupSchema = useMemo(() => {
        let schema = {
            push_rate: Yup.number()
                .typeError('Vui lòng nhập tỉ lệ đẩy')
                .min(0, 'Giá trị nhập trong khoảng từ 0 đến 100')
                .max(100, 'Giá trị nhập trong khoảng từ 0 đến 100')
        };

        return Yup.object().shape(schema)
    }, []);

    const resetValue = useCallback(() => {
        setValueRatio(text);
        setError(null);
        setShow(prev => !prev);
    }, [text]);

    return (
            <>
                <span ref={target} onClick={resetValue}>
                    {`${formatNumberToCurrency(text)}%`}
                    <i role="button" className="ml-2 far fa-edit" />
                </span>
                {show && <Overlay
                    rootClose
                    onHide={resetValue}
                    show={show}
                    target={target.current}
                    placement="right"
                >
                    <Popover>
                        <Popover.Title className="p-3" as="h6">{formatMessage({ defaultMessage: "Tỷ lệ đẩy" })}
                        </Popover.Title>
                        <Popover.Content>
                            <div className="d-flex justify-content-between" style={{ height: '30px' }}>
                                <NumberFormat
                                    className={clsx(`form-control mr-2`, { ['border border-danger']: !!error })}
                                    style={{ height: '30px' }}
                                    placeholder=""
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    decimalScale={0}
                                    value={valueRatio}
                                    onBlur={() => {                                            
                                        yupSchema.validate({push_rate: valueRatio}).then(value => {
                                            setError(null);
                                        }).catch(error => {
                                            setError(error?.message);
                                        })
                                    }}
                                    onValueChange={value => {                                             
                                        setError(null);
                                        setValueRatio(value?.value);
                                    }}
                                />
                                <AuthorizationWrapper keys={['setting_third_party_action']}>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => {
                                        yupSchema.validate({push_rate: valueRatio}).then(value => {
                                            !!onConfirm && onConfirm(valueRatio, () => {
                                                resetValue();
                                            })
                                        }).catch(error => {
                                            setError(error?.message);
                                        })
                                    }}
                                    className="mr-2 d-flex justify-content-center align-items-center">
                                    <i className="fas fa-check p-0 icon-nm" />
                                </Button>
                                </AuthorizationWrapper>
                                <Button
                                    variant="secondary"
                                    onClick={resetValue}
                                    size="sm"
                                    className="d-flex justify-content-center align-items-center">
                                    <i className="fas fa-times p-0 icon-nm" />
                                </Button>
                            </div>
                            {!!error && (
                                <span className='text-danger mt-2 d-block' style={{ maxWidth: '75%' }}>{error}</span>
                            )}
                        </Popover.Content>
                    </Popover>
                </Overlay>}
            </>
    )
};

export default memo(EditVertical);