import gql from 'graphql-tag';

export default gql`
    mutation cfExportTemplateOverview($template_id: Int!) {
        cfExportTemplateOverview(template_id: $template_id) {
            link
            message
            success
        }
    }
`;
