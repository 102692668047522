import gql from 'graphql-tag';

export default gql`
    query sme_catalog_inventory_items(
        $order_by: [sme_catalog_inventory_items_order_by!] = {
            updated_at: desc
            variant_id: desc
        }
        $limit: Int
        $offset: Int
        $where: sme_catalog_inventory_items_bool_exp = {}
    ) {
        sme_catalog_inventory_items(
            limit: $limit
            offset: $offset
            where: $where
            order_by: $order_by
        ) {
            sme_store_id
            stock_actual
            sme_store {
                id
                name
                code
            }
        }
        sme_catalog_inventory_items_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
