import gql from 'graphql-tag';

export default gql`
    mutation unlinkProductFromProvider(
        $link_ids: [Int!]!
        $provider_connected_id: Int!
    ) {
        unlinkProductFromProvider(
            link_ids: $link_ids
            provider_connected_id: $provider_connected_id
        ) {
            message
            success
            errors {
                message
                provider_sku
                sku
            }
        }
    }
`;
