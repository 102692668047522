import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as alert from "./alert"


export const rootReducer = combineReducers({
  auth: auth.reducer,
  alert: alert.reducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    alert.saga()
  ]);
}
