import React, { useMemo, useCallback, useState, memo, Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Table from 'rc-table';
import 'rc-table/assets/index.css';
import { useQuery, useMutation } from '@apollo/client';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useIntl } from 'react-intl';
import query_cfGetAllTemplateExport from '../../../../graphql/query_cfGetAllTemplateExport';
import { sub_tabs } from './constant';
import {useLocation, useHistory} from 'react-router-dom'
import queryString from 'querystring';
import mutate_cfUpdateTemplateStatus from '../../../../graphql/mutate_cfUpdateTemplateStatus';
import { useToasts } from 'react-toast-notifications';
import { ConfirmStatusDialog } from './dialogs/ComfirmStatusDialog';
import dayjs from 'dayjs';
import { Dropdown } from 'react-bootstrap';
import LoadingDialog from "../../ProductsStore/product-new/LoadingDialog";
import mutate_cfExportTemplateOverview from '../../../../graphql/mutate_cfExportTemplateOverview';
import { saveAs } from 'file-saver';
import AuthorizationWrapper from '../../../../components/AuthorizationWrapper';
import { useSelector } from 'react-redux';

const SettingTemplate = () => {    
    const { formatMessage } = useIntl();
    const [currentData, setCurrentData] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const { addToast } = useToasts();
    const location = useLocation();
    const history = useHistory()
    const params = queryString.parse(location.search.slice(1, 100000))
    const user = useSelector((state) => state.auth.user);   

    const { loading: loadingGetAllTemplate, data: dataAllTemplate } = useQuery(query_cfGetAllTemplateExport, {
        fetchPolicy: 'cache-and-network',
        variables: {
            type: +params?.type || 1
        }
    });    

    const [updateStatus, { loading: loadingUpdateStatus }] = useMutation(mutate_cfUpdateTemplateStatus, {
        refetchQueries: ['cfGetAllTemplateExport']
    })

    const [exportFile, {loading: loadingExportFile}] = useMutation(mutate_cfExportTemplateOverview)

    const columns = [
        {
            title: formatMessage({ defaultMessage: 'Tên mẫu' }),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            fixed: 'left',
            align: 'left',
            render: (item, record) => {
                return <div className='d-flex align-items-center'>
                    <span>{record?.name || '--'}</span>
                </div>
            }
        },
        {
            title: formatMessage({ defaultMessage: 'Sử dụng' }),
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            fixed: 'center',
            align: 'center',
            render: (item, record) => {
                const isDisable = user?.is_subuser && !['setting_finance_action']?.some(key => user?.permissions?.includes(key))
                return <div className='d-flex justify-content-center'>
                    <span className="switch" style={{ transform: 'scale(0.8)' }}>
                        <label>
                            <input
                                type={'checkbox'}
                                disabled={isDisable}
                                onChange={async () => {
                                    if (record?.status == 1) {
                                        setShowConfirm(true);
                                        setCurrentData(record);
                                        return
                                    }
                                    const {data: dataUpdateStatus} = await updateStatus({
                                        variables: {
                                            template_id: record?.id,
                                            status: record?.status == 1 ? 0 : 1
                                        }
                                    })
                                    if(dataUpdateStatus?.cfUpdateTemplateStatus?.success){
                                        addToast(formatMessage({defaultMessage: "Bật cấu hình mẫu thành công"}), { appearance: 'success' })
                                    } else {
                                        addToast(dataUpdateStatus?.cfUpdateTemplateStatus?.message || formatMessage({defaultMessage: "Bật cấu hình mẫu thất bại"}), { appearance: 'error' })
                                    }
                                }}
                                style={{ background: '#F7F7FA', border: 'none' }}
                                checked={record?.status == 1}
                            />
                            <span></span>
                        </label>
                    </span>
                </div>
            }
        },
        {
            title: formatMessage({ defaultMessage: 'Thời gian tạo' }),
            dataIndex: 'created_at',
            key: 'created_at',
            width: '20%',
            fixed: 'center',
            align: 'center',
            render: (item, record) => {
                return <span>{!!record?.created_at ? dayjs(record?.created_at).format('DD/MM/YYYY HH:mm') : '--'}</span>
            }
        },
        {
            title: formatMessage({ defaultMessage: 'Thời gian cập nhật' }),
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: '20%',
            fixed: 'center',
            align: 'center',
            render: (item, record) => {
                return <span>{!!record?.updated_at ? dayjs(record?.updated_at).format('DD/MM/YYYY HH:mm') : '--'}</span>
            }
        },
        {
            title: formatMessage({ defaultMessage: 'Thao tác' }),
            dataIndex: 'store_id',
            key: 'store_id',
            width: '20%',
            fixed: 'center',
            align: 'center',
            render: (item, record) => {
                return (
                    <AuthorizationWrapper keys={['setting_finance_action']}>
                    <div>
                        <Dropdown drop='down' >
                            <Dropdown.Toggle className='btn-outline-primary' >
                                {formatMessage({ defaultMessage: 'Chọn' })}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    record?.is_private == 1 &&
                                        <>
                                            <Dropdown.Item className="mb-1 d-flex" onClick={() => {
                                                history.push({
                                                    pathname: '/setting/setting-finance-update',
                                                    state: { 
                                                        id: record?.id,
                                                        isCopy: false
                                                    }
                                                });
                                            }}>{formatMessage({ defaultMessage: 'Chỉnh sửa' })}</Dropdown.Item>
                                            <Dropdown.Item className="mb-1 d-flex" onClick={async () => {
                                                let {data} = await exportFile({
                                                    variables: {
                                                        template_id: +record?.id
                                                    }
                                                })
                                                if(data?.cfExportTemplateOverview?.success) {
                                                    saveAs(data?.cfExportTemplateOverview?.link, record?.name)
                                                } else {
                                                    addToast(data?.cfExportTemplateOverview?.message || formatMessage({defaultMessage: 'Xuất file mẫu thất bại'}), {appearance: 'error'})
                                                }
                                            }}>{formatMessage({ defaultMessage: 'Xuất file mẫu' })}</Dropdown.Item>
                                        </>
                                }
                                <Dropdown.Item className="mb-1 d-flex" onClick={() => {
                                    history.push({
                                        pathname: '/setting/setting-finance-update',
                                        state: { 
                                            id: record?.id,
                                            isCopy: true
                                        }
                                    });
                                }}>{formatMessage({ defaultMessage: 'Sao chép mẫu' })}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    </AuthorizationWrapper>
                )
            }
        },
    ];

    return (
        <Fragment>
            <LoadingDialog show={loadingUpdateStatus} />
            {showConfirm && <ConfirmStatusDialog 
                handleSubmit={async () => {
                    const {data: dataUpdateStatus} = await updateStatus({
                        variables: {
                            template_id: currentData?.id,
                            status: currentData?.status == 1 ? 0 : 1
                        }
                    })
                    if(dataUpdateStatus?.cfUpdateTemplateStatus?.success){
                        addToast(formatMessage({defaultMessage: "Tắt cấu hình mẫu thành công"}), { appearance: 'success' })
                    } else {
                        addToast(dataUpdateStatus?.cfUpdateTemplateStatus?.message || formatMessage({defaultMessage: "Tắt cấu hình mẫu thất bại"}), { appearance: 'error' })
                    }
                    setShowConfirm(false)
                }}
                onHide={() => setShowConfirm(false)}
            />}
            <div className='mt-2 mb-4 d-flex align-items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2 text-info bi bi-info-circle" viewBox="0 0 18 18">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
                <span className='text-info'>
                    {formatMessage({ defaultMessage: 'Mẫu sử dụng cho việc đẩy dữ liệu chứng từ bán hàng lên các hệ thống kế toán' })}
                </span>
            </div>
            <div className="d-flex w-100 mt-2 mb-4" style={{ background: "#fff", zIndex: 1, borderBottom: 'none' }}>
                <div style={{ flex: 1 }}>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {sub_tabs.map((_tab, index) => {
                            const { title, key } = _tab;
                            const isActive = key == (params?.type || '1');
                            return (
                                <>
                                    <li style={{ cursor: 'pointer' }}
                                        key={`tab-order-${index}`}
                                        className={`nav-item ${isActive ? "active" : ""}`}>
                                        <span className={`nav-link font-weight-normal ${isActive ? "active" : ""}`}
                                            style={{ fontSize: "13px" }}
                                            onClick={() => {
                                                history.push(`${location.pathname}?${queryString.stringify({ 
                                                    ...params,
                                                    type: key 
                                                })}`);
                                            }}
                                        >
                                            {formatMessage(title)}
                                        </span>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                {loadingGetAllTemplate && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 99 }}>
                        <span className="spinner spinner-primary" />
                    </div>
                )}
                <Table
                    className="upbase-table"
                    style={loadingGetAllTemplate ? { opacity: 0.4 } : {}}
                    columns={columns}
                    data={dataAllTemplate?.cfGetAllTemplateExport?.data || []}
                    emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                        <img src={toAbsoluteUrl("/media/empty.png")} alt="image" width={80} />
                        <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                    </div>}
                    tableLayout="auto"
                    sticky={{ offsetHeader: 45 }}
                />
            </div>
        </Fragment>
    )
};

export default memo(SettingTemplate);