import { Field, Formik } from 'formik'
import React, { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { TextArea } from '../../../../../_metronic/_partials/controls'
import * as Yup from 'yup'
const ModalCreate = ({ onHide, onSubmit }) => {
    const { formatMessage } = useIntl()
    const [initValidate, setInitValidate] = useState({})
    useMemo(() => {
        let validate = []

        validate['skus'] = Yup.string().required('Vui lòng nhập các mã SKU.')
            .max(1000, formatMessage({ defaultMessage: "Nhập tối đa 1000 kí tự." }))
            .test('chua-ky-tu-space-o-dau-cuoi', formatMessage({ defaultMessage: 'Thông tin nhập vào đang chứa dấu cách ở đầu hoặc cuối' }),
                (value, context) => {
                    if (!!value) {
                        return value.length == value.trim().length;
                    }
                    return false;
                })
        setInitValidate(Yup.object().shape(validate));
    }, [])

    return (
        <div>
            <Formik initialValues={{skus: ''}} validationSchema={initValidate}
                onSubmit={onSubmit}>
                {({ values, handleSubmit, setFieldValue }) => {
                    return (
                        <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-sm" dialogClassName="modal-show-connect-product" centered onHide={() => { }} backdrop={true}>
                            <Modal.Header closeButton={false}>
                                <Modal.Title>
                                    {formatMessage({ defaultMessage: "Tạo mới sản phẩm từ KiotViet sang UpS" })}
                                </Modal.Title></Modal.Header>
                            <Modal.Body>
                                <>
                                    <div className='mb-2'>{formatMessage({ defaultMessage: 'SKU hàng hóa' })}</div>
                                    <Field name='skus'
                                        component={TextArea}
                                        placeholder={formatMessage({ defaultMessage: "Nhập các mã SKU cách nhau bởi dấu phẩy", })}
                                        label={""}
                                        value={values['skus']}
                                        required={false}
                                        customFeedbackLabel={" "}
                                        cols={["col-0", "col-12"]}
                                        countChar
                                        rows={3}
                                        maxChar={"1000"} />
                                </>
                            </Modal.Body>
                            <Modal.Footer className="form" style={{ borderTop: "1px solid #dbdbdb", justifyContent: "end", paddingTop: 10, paddingBottom: 10 }}>
                                <div className="form-group">
                                    <button type="button" className="btn btn-light btn-elevate mr-3" style={{ width: 100, background: 'gray', color: 'white' }}
                                        onClick={() => onHide()}>
                                        {formatMessage({ defaultMessage: "Hủy" })}
                                    </button>
                                    <button disabled={!values['skus']} type="button" className="btn btn-primary mr-3" style={{ width: 100 }} onClick={handleSubmit}>
                                        {formatMessage({ defaultMessage: "Gọi tạo mới" })}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ModalCreate
