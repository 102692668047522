import gql from 'graphql-tag';

export default gql`
    query userGetListProductSyncFullfillment(
        $smeBrandId: Int
        $page: Int
        $pageSize: Int
        $connectedProviderId: Int!
        $searchText: String
        $type: String!
        $status: String
    ) {
        userGetListProductSyncFullfillment(
            smeBrandId: $smeBrandId
            page: $page
            pageSize: $pageSize
            connectedProviderId: $connectedProviderId
            searchText: $searchText
            type: $type
            status: $status
        ) {
            currentPage
            itemCount
            items {
                name
                sku
                stockActual
                stockAvailable
                stockReserve
                errorMessage
                synced
                syncedDate
                updatedDate
                inventoryUpdateDate
                variant_id
                provider_sku
            }
            pageCount
            pageSize
        }
    }
`;
