import gql from 'graphql-tag';

export default gql`
    query userGetListProductSyncFulfillmentKiotViet(
        $page: Int
        $pageSize: Int
        $connectedProviderId: Int!
        $searchText: String
        $type: String!
        $status: String
    ) {
        userGetListProductSyncFulfillmentKiotViet(
            page: $page
            pageSize: $pageSize
            connectedProviderId: $connectedProviderId
            searchText: $searchText
            type: $type
            status: $status
        ) {
            currentPage
            itemCount
            items {
                name
                sku
                variant_id
                product_links {
                    created_at
                    id
                    inventory_updated_at
                    product_updated_at
                    provider_sku
                    sync_error_msg
                    updated_at
                }
            }
            pageCount
            pageSize
        }
    }
`;
