import { FormattedMessage, defineMessages } from 'react-intl'
import React from 'react'

const defineTextSub = defineMessages({
    synced_success: {
        defaultMessage: "Đã đồng bộ"
    },
    not_synced: {
        defaultMessage: "Chưa đồng bộ"
    },
    synced_faild: {
        defaultMessage: "Đồng bộ thất bại"
    },
})

const defineTextSubKiotViet = defineMessages({
    connected: {
        defaultMessage: "Đã liên kết hàng hóa"
    },
    not_connected: {
        defaultMessage: "Chưa liên kết hàng hóa"
    }
})

const defineTextTab = defineMessages({
    synced_info: {
        defaultMessage: "Thông tin đồng bộ"
    },
    setting: {
        defaultMessage: "Cấu hình"
    },
    warehouse_management: {
        defaultMessage: 'Quản lý liên kết kho'
    }
})

const defineSearchOption = defineMessages({
    synced_success: {
        defaultMessage: "Đồng bộ thành công"
    },
    synced_failed: {
        defaultMessage: "Đồng bộ lỗi"
    }
})

const SUBTAB = [
    {
        title: defineTextSub.synced_success,
        status: 'synced_success',
    },
    {
        title: defineTextSub.not_synced,
        status: 'not_synced'
    },
    {
        title: defineTextSub.synced_faild,
        status: 'synced_faild',
    },

]

const SUBTAB_KIOTVIET = [
    {
        title: defineTextSubKiotViet.connected,
        status: 'linked',
    },
    {
        title: defineTextSubKiotViet.not_connected,
        status: 'not_linked'
    }
]

const TAB = [
    {
        title: defineTextTab.synced_info,
        key: 'synced_info',
    },
    {
        title: defineTextTab.setting,
        key: 'setting'
    },
    {
        title: defineTextTab.warehouse_management,
        key: 'warehouse_management'
    }
]

const SEARCH_OPTIONS = [
    {
        label: defineSearchOption.synced_success,
        value: 'success'
    },
    {
        label: defineSearchOption.synced_failed,
        value: 'error'
    }
]

export { SUBTAB, TAB, SEARCH_OPTIONS, SUBTAB_KIOTVIET}