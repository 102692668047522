import gql from 'graphql-tag';

export default gql`
    query sme_store_provider_link(
        $where: sme_store_provider_link_bool_exp
        $limit: Int!
        $offset: Int!
    ) {
        sme_store_provider_link(limit: $limit, offset: $offset, where: $where) {
            created_at
            fulfillment_provider_connected_id
            fulfillment_provider_name
            fulfillment_provider_ref
            fulfillment_provider_system_code
            id
            sme_id
            store_id
            updated_at
        }
    }
`;
