import gql from 'graphql-tag';

export default gql`
mutation scLoadAttributeMissing($store_id: Int!) {
  scLoadAttributeMissing(store_id: $store_id) {
    tracking_id
    message
    success
  }
}
`;
