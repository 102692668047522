import gql from 'graphql-tag';

export default gql`
    mutation userUpsertSmeStoreProviderLink(
        $userUpsertSmeStoreProviderLinkInput: UserUpsertSmeStoreProviderLinkInput!
    ) {
        userUpsertSmeStoreProviderLink(
            userUpsertSmeStoreProviderLinkInput: $userUpsertSmeStoreProviderLinkInput
        ) {
            id
            message
            success
        }
    }
`;
