import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useSubheader } from '../../../../_metronic/layout';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'querystring';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useToasts } from 'react-toast-notifications'
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { useQuery } from '@apollo/client';
import ConfigTemplateTable  from './components/ConfigTemplateTable'
import query_cfGetConfigTemplateExport from '../../../../graphql/query_cfGetConfigTemplateExport';
import query_sc_stores_basic from '../../../../graphql/query_sc_stores_basic';
const UpdateFinanceTemplate = () => {
    const { setBreadcrumbs } = useSubheader();
    const { formatMessage } = useIntl()
    const location = useLocation();
    const history = useHistory()
    const params = queryString.parse(location.search.slice(1, 100000))
    const { id, isCopy } = location.state || {}

    useLayoutEffect(() => {
        setBreadcrumbs([{ title: formatMessage({ defaultMessage: "Cài đặt" }) }, { title: formatMessage({ defaultMessage: "Cài đặt tài chính" }) }, { title: formatMessage({ defaultMessage: "Cập nhật file mẫu" }) }]);
    }, []);

    const {data: dataConfigTemplate, loading: loadingConfigTemplate} = useQuery(query_cfGetConfigTemplateExport, {
        variables: {
            template_id: +id
        },
        fetchPolicy: 'cache-and-network',
    })

    const { data: dataStore, loading: loadingGetStore } = useQuery(query_sc_stores_basic, {
        variables: {
            context: 'order'
        },
        fetchPolicy: "cache-and-network",
    });

    return (
        <>
            <Helmet titleTemplate={formatMessage({ defaultMessage: `Cài đặt tài chính {key}` }, { key: " - UpS" })} defaultTitle={formatMessage({ defaultMessage: `Cài đặt tài chính {key}` }, { key: " - UpS" })}>
                <meta name="description" content={formatMessage({ defaultMessage: `Cài đặt tài chính {key}` }, { key: " - UpS" })} />
            </Helmet>
            {dataConfigTemplate?.cfGetConfigTemplateExport && dataStore?.sc_stores && <ConfigTemplateTable id={id} isCopy={isCopy} itemUpdate={dataConfigTemplate?.cfGetConfigTemplateExport} dataStore={dataStore}/>}
        </>
    )
}

export default UpdateFinanceTemplate