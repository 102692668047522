import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import query_userGetListWarehouseFullfillment from "../../../../../graphql/query_userGetListWarehouseFullfillment";
import makeAnimated from 'react-select/animated';
import { useQuery } from "@apollo/client";
import Select from "react-select";

const animatedComponents = makeAnimated();

const ModalConnectStoreWh = ({ store, onSubmit, onHide }) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const params = queryString.parse(location.search.slice(1, 100000));
    const [currentWh, setCurrentWh] = useState(null)

    const { data: dataWarehouse, loading: loadingDataProvider } = useQuery(
        query_userGetListWarehouseFullfillment,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                connectedProviderId: +params?.id,
                fulfillmentBy: 'online'
            },
            skip: !params?.id,
        }
    );
    const optionsWarehouseProvider = useMemo(() => {
        if(!dataWarehouse?.userGetListWarehouseFullfillment?.data?.length) return []
        return dataWarehouse?.userGetListWarehouseFullfillment?.data?.map(item => ({
            ...item,
            value: item?.code,
            label: item?.name
        }))
    }, [dataWarehouse])
    useMemo(() => {
        if(store?.fulfillment_provider_ref) {
            setCurrentWh(optionsWarehouseProvider?.find(item => item?.code == store?.fulfillment_provider_ref))
        }
    }, [store, optionsWarehouseProvider])
    return (
        <Modal
            show={true}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
            size="sm"
            backdrop={true}
            dialogClassName={'body-dialog-connect'}
        >
            <Modal.Header>
                <div className="d-flex align-items-center">
                    <strong style={{fontSize: 16}}>
                        {formatMessage({
                            defaultMessage: 'Thay đổi liên kết kho',
                        })}
                    </strong>
                </div>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                <div className="mb-4">
                    <strong>
                        {formatMessage({ defaultMessage: 'Gian hàng: ' })}
                    </strong>
                    <img
                        src={store?.logo}
                        style={{
                            width: 20,
                            height: 20,
                            marginRight: 8,
                            marginLeft: 8,
                        }}
                        alt=""
                    />
                    <span>{store?.name}</span>
                </div>
                <strong>
                    {formatMessage({ defaultMessage: 'Kho hàng liên kết' })}
                </strong>
                <Select
                    options={optionsWarehouseProvider || []}
                    className="w-100 select-report-custom"
                    placeholder={formatMessage({ defaultMessage: 'Chọn kho hàng liên kết' })}
                    components={animatedComponents}
                    value={currentWh}
                    isLoading={loadingDataProvider}
                    onChange={(value) => {
                        if(value?.value) {
                            setCurrentWh(value)
                        } else {
                            setCurrentWh(null)
                        }
                    }}
                    formatOptionLabel={(option, labelMeta) => {
                        return (
                            <div>{option.label}</div>
                        );
                    }}
                />
            </Modal.Body>
            <Modal.Footer
                className="form"
                style={{
                    borderTop: '1px solid #dbdbdb',
                    justifyContent: 'end',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <div className="form-group">
                    <button
                        className="btn btn-light btn-elevate mr-3"
                        style={{ width: 100 }}
                        onClick={onHide}
                    >
                        {formatMessage({ defaultMessage: 'Hủy' })}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        style={{ width: 100 }}
                        onClick={() => onSubmit(currentWh)}
                    >
                        {formatMessage({ defaultMessage: 'Đồng ý' })}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConnectStoreWh