import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import query_stores_expried from '../graphql/query_stores_expried';
import dayjs from 'dayjs';
import { toAbsoluteUrl } from "../_metronic/_helpers";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/alert';
import query_coCheckWarehouseNotProcessPackage from '../graphql/query_coCheckWarehouseNotProcessPackage'

const STATUS = {
  DISCONNECT: 0,
  CONNECTED: 1,
  LOST_CONNECTED: 2,
}

const Alert = () => {
  const [stores, setStores] = useState([])
  const [storeMissAttribute, setStoreMissAttribute] = useState([])
  const _storeMissAttribute = useSelector((state) => state?.alert?.listMissAttributes || [])

  useEffect(() => {
    setStoreMissAttribute(_storeMissAttribute)
  }, [_storeMissAttribute])

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  function timeStoreExpired(time) {
    return {
      days: Math.floor((dayjs(time).unix() - dayjs().unix()) / (60 * 60 * 24)),
      hours: Math.floor(((dayjs(time).unix() - dayjs().unix()) % (60 * 60 * 24)) / (60 * 60))
    }
  }

  function amountDay(time) {
    if (timeStoreExpired(time)?.days === 0) {
      return timeStoreExpired(time)?.hours < 10 ?
        formatMessage({ defaultMessage: "0{time} giờ" }, { time: timeStoreExpired(time)?.hours })
        : formatMessage({ defaultMessage: "{time} giờ" }, { time: timeStoreExpired(time)?.hours })
    }
    return formatMessage({ defaultMessage: "{time} ngày" }, { time: timeStoreExpired(time)?.days })
  }
  useQuery(query_stores_expried, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const channels = data?.op_connector_channels?.map(channel => {
        return {
          logo: channel?.logo_asset_url,
          code: channel?.code
        }
      })
      const storesExpried = data?.sc_stores?.flatMap(store => {
        const channel = channels?.find(cn => cn?.code == store?.connector_channel_code)
        if (store?.status == STATUS['LOST_CONNECTED']) {
          return {
            ...store,
            logo: channel?.logo
          }
        }
        if (!!store?.authorization_expired_at &&
          timeStoreExpired(store?.authorization_expired_at)?.days < 8 &&
          timeStoreExpired(store?.authorization_expired_at)?.days >= 0) {
          return {
            ...store,
            logo: channel?.logo,
            timeExpired: amountDay(store?.authorization_expired_at)
          }
        }
        return []
      })
      setStores(storesExpried)
    }
  });

  useEffect(() => {
    dispatch(actions.missAttribute())
    const loop = setInterval(() => {
      dispatch(actions.missAttribute()) // loop để xử lý cho cả phần tải sản phẩm
    }, 5000)

    return () => {
      clearInterval(loop)
    }
  }, [])

  const { data: checkWarehouseNotProcessPackage } = useQuery(query_coCheckWarehouseNotProcessPackage)
  return (
    <>
      {!!stores.length && (
        <div className='mb-2'>
          {stores?.map((store, index) => (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              background: '#f5c6cb',
              color: '#721c24',
              alignItems: 'center',
              padding: '7px 7px',
              marginBottom: '2px',
              borderRadius: '5px'
            }}
              key={index}
            >
              <div className='d-flex align-items-center'>
                <img src={toAbsoluteUrl("/media/warningsvg.svg")} alt=""></img>

                <p className='ml-2 mb-0'>
                  {store?.status == STATUS['LOST_CONNECTED'] ?
                    <span>{formatMessage({ defaultMessage: "Gian hàng" })}
                      <strong style={{ margin: '0 5px' }}> <img style={{ width: '15px', marginBottom: '3px' }} src={store?.logo} alt='' /> {store?.name} </strong>
                      {formatMessage({ defaultMessage: "bị mất kết nối. Vui lòng đến " })}
                      <Link to="/setting/channels"> {formatMessage({ defaultMessage: "Kênh bán" })} </Link>
                      {formatMessage({ defaultMessage: "để kết nối lại" })}.
                    </span>
                    :
                    <span>{formatMessage({ defaultMessage: "Gian hàng" })}
                      <strong style={{ margin: '0 5px' }}> <img style={{ width: '15px', marginBottom: '3px' }} src={store?.logo} alt='' /> {store?.name} </strong>
                      {formatMessage({ defaultMessage: "sẽ hết hạn uỷ quyền trong" })}
                      <strong> {store?.timeExpired} </strong>
                      {formatMessage({ defaultMessage: "nữa. Vui lòng đến" })}
                      <Link to="/setting/channels"> {formatMessage({ defaultMessage: "Kênh bán" })} </Link>
                      {formatMessage({ defaultMessage: "để kết nối lại" })}.
                    </span>}
                </p>
              </div>
              <div onClick={() => setStores((prev) => prev.slice(0, -1))} style={{ cursor: 'pointer' }}>
                <X />
              </div>
            </div>
          )
          )}
        </div>
      )}
      {
        storeMissAttribute?.map((store, key) => {
          return <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#f5c6cb',
            color: '#721c24',
            alignItems: 'center',
            padding: '7px 7px',
            marginBottom: '2px',
            borderRadius: '5px'
          }}
            key={key}
          >
            <div className='d-flex align-items-center'>
              <img src={toAbsoluteUrl("/media/warningsvg.svg")} alt=""></img>
              <span className='ml-2 mb-0'>
                {formatMessage({ defaultMessage: 'Gian hàng' })}
                <strong style={{ margin: '0 4px' }}> <img style={{ width: '15px', marginBottom: '3px' }} src={store?.store?.logo} alt='' /> {store?.store?.name} </strong>
                {formatMessage({ defaultMessage: 'có' })}
                <strong style={{ margin: '0 4px' }}> {store?.list_category?.length} </strong>
                {formatMessage({ defaultMessage: "danh mục thiếu thuộc tính," })}
                <Link to={"/setting/channel/" + store?.store?.id}> {formatMessage({ defaultMessage: "bấm vào đây" })} </Link>
                {formatMessage({ defaultMessage: "để tải lại thuộc tính cho danh mục" })}.
              </span>
            </div>
            <div onClick={() => setStoreMissAttribute((prev) => prev.filter(s => s?.store?.id != store?.store?.id))} style={{ cursor: 'pointer' }}>
              <X />
            </div>
          </div>
        })
      }
      {
        (checkWarehouseNotProcessPackage?.coCheckWarehouseNotProcessPackage?.exists >= 1) &&
        <span style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: '#f5c6cb',
          color: '#721c24',
          alignItems: 'center',
          padding: '7px 7px',
          marginBottom: '2px',
          borderRadius: '5px'
        }}>
          <div className='d-flex align-items-center' style={{ fontWeight: 500 }}>
            <img src={toAbsoluteUrl("/media/warningsvg.svg")} alt=""></img>
            <span className='ml-2'>
              {formatMessage({ defaultMessage: "Có" })} &nbsp;
              {checkWarehouseNotProcessPackage?.coCheckWarehouseNotProcessPackage?.exists} &nbsp;
              {formatMessage({ defaultMessage: "đơn hàng đang không có kho nhận xử lý." })} &nbsp;
              <a style={{ textDecoration: "underline" }} href='/orders/list?type=not_process_wh_1'>{formatMessage({ defaultMessage: "Xem tại đây" })}</a>
            </span>
          </div>
          <div onClick={(e) => {
            e.currentTarget.parentElement.style.display = "none"
          }} style={{ cursor: 'pointer' }}>
            <X />
          </div>
        </span>
      }
    </>
  )
}

const X = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#595959" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>

export default Alert