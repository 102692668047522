import React, { memo, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from "react-intl";
import { useMutation, useQuery } from '@apollo/client';
import mutate_scLoadAttributeMissing from '../../../../graphql/mutate_scLoadAttributeMissing';
import ModalLoadAttributeMissing from './components/ModalLoadAttributeMissing';
import query_scJobTracking from '../../../../graphql/query_scJobTracking';
import { actions } from '../../../../redux/alert';
const AlertMissAttribute = ({ store, channel }) => {
    const { formatMessage } = useIntl()
    const [trackingId, setTrackingId] = useState(0);
    const dispatch = useDispatch()
    const [progressBar, setProgress] = useState({ success: 0, failed: 0, progress: 0 })
    const [pollTimeJobTracking, setPollTimeJobTracking] = useState(0);
    const [showModalLoadAttribute, setShowModalLoadAttribute] = useState(false); //]
    const listMissAttributes = useSelector((state) => state?.alert?.listMissAttributes || [])
    const [loadAttribute] = useMutation(mutate_scLoadAttributeMissing);

    const { data } = useQuery(query_scJobTracking,
        {
            fetchPolicy: "no-cache",
            variables: {
                id: trackingId
            },
            pollInterval: !!trackingId ? pollTimeJobTracking : 0,
            skip: !trackingId,
        }
    )

    useMemo(() => {
        const sc_job_tracking = data?.sc_job_tracking
        if (!sc_job_tracking) {
            setPollTimeJobTracking(0); //
            return;
        }
        let failedJob = sc_job_tracking?.failed_job;
        let successJob = sc_job_tracking?.success_job;
        let totalJob = sc_job_tracking?.total_job;

        if (totalJob > 0) {
            let _progressBar = Math.floor(((successJob + failedJob) / totalJob) * 100);
            if (_progressBar > progressBar.progress)
                setProgress({
                    success: successJob,
                    failed: failedJob,
                    progress: _progressBar > 100 ? 100 : _progressBar
                })
        }

        if (successJob + failedJob >= totalJob) {
            setPollTimeJobTracking(0); //
            dispatch(actions.missAttribute())
        }
    }, [data])

    const nameStore = (store) => {
        return (
            <div style={{ cursor: 'pointer' }}>
                {!!channel && <img src={channel.logo_asset_url} className={` mr-2`} style={{ width: 24, height: 24 }} />}
                <span className={`font-size-h7`}>
                    {store.name}
                </span>
            </div>
        );
    }

    const miss = listMissAttributes?.find(_ => _?.store?.id == store?.id)
    return <>
        {!!miss && <span className='text-primary'>
            {formatMessage({ defaultMessage: "Bạn đang có" })} &nbsp;
            {miss?.list_category?.length}&nbsp;
            {formatMessage({ defaultMessage: "danh mục thiếu thuộc tính, vui lòng" })}&nbsp;
            <span style={{ cursor: 'pointer', color: '#0a5dc2' }} onClick={async () => {
                setShowModalLoadAttribute(true)
                const { data } = await loadAttribute({ variables: { store_id: store?.id } })
                const tracking_id = data?.scLoadAttributeMissing?.tracking_id
                setTrackingId(tracking_id);
                setPollTimeJobTracking(2000);
            }}>{formatMessage({ defaultMessage: "bấm vào đây" })}</span>&nbsp;
            {formatMessage({ defaultMessage: "để tải lại thuộc tính" })}
        </span>}
        <ModalLoadAttributeMissing
            show={showModalLoadAttribute}
            onHide={() => {
                setShowModalLoadAttribute(false);
                setPollTimeJobTracking(0);
                setTrackingId(0);
                setProgress({ success: 0, failed: 0, progress: 0 })
            }}
            currentInfoStore={store}
            nameStore={nameStore}
            progressBar={progressBar} />
    </>
}

export default memo(AlertMissAttribute)