import gql from 'graphql-tag';

export default gql`
    mutation cfUpdateConfigColumns(
        $list_columns: [CfUpdateConfigColumnsInput!]!
        $template_code: String!
        $template_config: String!
        $template_id: Int!
        $template_name: String !
        $template_type: Int!
    ) {
        cfUpdateConfigColumns(
            list_columns: $list_columns
            template_code: $template_code
            template_config: $template_config
            template_id: $template_id
            template_name: $template_name
            template_type: $template_type
        ) {
            message
            success
        }
    }
`;
