import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import Channels from "./channels/Channels";
import Brands from "./brands";
import ChannelsAddDialog from "./channels/ChannelsAddDialog";
import ChannelsAddRedirectDialog from "./channels/ChannelsAddRedirectDialog";
import ChannelsConfirmUnlinkDialog from "./channels/ChannelsConfirmUnlinkDialog";
import ChannelsDetailPage from "./channels/ChannelsDetailPage";
import ChannelsSyncErrorDialog from "./channels/ChannelsSyncErrorDialog";
import ChangePassword from "./users/ChangePassword";
import CreateGroupPermission from "./profile/CreateGroupPermission";
import CreateMember from "./profile/CreateMember";
import EditProfile from "./profile/EditProfile";
import ProfilePage from "./profile/ProfilePage";
import SettingPage from "./SettingPage";
import UsersManagement from './users/index';
import CreateSubUser from "./users/CreateSubUser";
import ChangePasswordSubUser from "./users/ChangePasswordSubUser";
import UpdateSubUser from "./users/UpdateSubUser";
import SyncWarehouseSetting from "./channels/SyncWarehouseSetting";
import SettingFinance from "./settingfinance";
import SettingProductStatus from "./SettingProductStatus";
import SettingPushInventory from "./SettingPushInventory";
import ThirdPartyConnection from "./thirdPartyConnection";
import SettingProvider from "./settingProvider";
import SettingProviderConfig from "./SettingProviderConfig";
import CreateRoles from "./users/roles/CreateRoles";
import DetailRoles from "./users/roles/DetailRoles";
import SettingShipping from "./shipping";
import Ads from "./ads";
import AdsAddRedirectDialog from "./ads/components/AdsAddRediractDialog";
import UpdateFinanceTemplate from "./setting-finance-update";
import HistoryConnectProvider from "./history-connect-provider";

export default function SettingsPage() {
    const suhbeader = useSubheader();
    suhbeader.setTitle(null);

    useEffect(() => {
    }, [])
    return (
        <>
            <Switch>
                <ContentRoute
                    path="/setting/channel/:id"
                    component={ChannelsDetailPage}
                    roles={['setting_channel_view']}
                />
                <ContentRoute
                    path="/setting/channels"
                    component={Channels}
                    roles={['setting_channel_view']}
                />
                <ContentRoute
                    path="/setting/brands"
                    component={Brands}
                    roles={['only-main-user']}
                />
                 <ContentRoute
                    path="/setting/ads"
                    component={Ads}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/sync-warehouse"
                    component={SyncWarehouseSetting}
                    roles={['setting_sync_warehouse_view']}
                />
                <ContentRoute
                    path="/setting/history-connect-provider"
                    component={HistoryConnectProvider}
                    roles={['']}
                />
                <ContentRoute
                    path="/setting/setting-push-inventory"
                    component={SettingPushInventory}
                    roles={['setting_sync_warehouse_view']}
                />
                <ContentRoute
                    path="/setting/third-party-connection"
                    component={ThirdPartyConnection}
                    exact
                    roles={['setting_third_party_view']}
                />
                <ContentRoute
                    path="/setting/third-party-connection/config/:id"
                    exact
                    component={SettingProviderConfig}
                    roles={['setting_third_party_view']}
                />
                <ContentRoute
                    path="/setting/third-party-connection/:id"
                    exact
                    component={SettingProvider}
                    roles={['setting_third_party_view']}
                />
                <ContentRoute
                    path="/setting/users/create-role"
                    component={CreateRoles}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/users/role/:id"
                    component={DetailRoles}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/users/create-sub-user"
                    component={CreateSubUser}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/users/change-password-sub-user"
                    component={ChangePasswordSubUser}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/users/change-password"
                    component={ChangePassword}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/users/update-sub-user"
                    component={UpdateSubUser}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/users"
                    component={UsersManagement}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/profile/edit-member/:id"
                    component={CreateMember}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/profile/add-member"
                    component={CreateMember}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/profile/create-group-permission"
                    component={CreateGroupPermission}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/profile/edit"
                    component={EditProfile}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/profile"
                    component={ProfilePage}
                    roles={['only-main-user']}
                />
                <ContentRoute
                    path="/setting/setting-finance"
                    component={SettingFinance}
                    roles={['setting_finance_view']}
                />
                <ContentRoute
                    path="/setting/setting-finance-update"
                    component={UpdateFinanceTemplate}
                    roles={['setting_finance_action']}
                />
                <ContentRoute
                    path="/setting/setting-product-status"
                    component={SettingProductStatus}
                    roles={['setting_product_status_view']}
                />
                <ContentRoute
                    path="/setting/setting-shipping"
                    component={SettingShipping}
                    roles={['group_setting_shipping_configuration_view']}
                />
                <ContentRoute
                    path="/setting"
                    component={SettingPage}
                    roles={['setting_channel_view']}
                />

            </Switch>
            <Route exact path="/setting/channels/add">
                {({ history, match }) => (
                    <ChannelsAddDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/setting/channels");
                        }}
                    />
                )}
            </Route>
            <Route exact path="/setting/channels/add/:channel/connect-redirect">
                {({ history, match }) => (
                    <ChannelsAddRedirectDialog
                        show={match != null}
                        onHide={() => {
                            history.push({
                                pathname: "/setting/channels",
                                state: { reloadStore: true }
                            });
                        }}
                    />
                )}
            </Route>

            <Route exact path="/setting/ads/add/:channel/connect-redirect">
                {({ history, match }) => (
                    <AdsAddRedirectDialog
                        show={match != null}
                        onHide={() => {
                            history.push({
                                pathname: "/setting/ads",
                                state: { reloadStore: true }
                            });
                        }}
                    />
                )}
            </Route>
            <Route exact path="/setting/channels/unlink/:id">
                {({ history, match }) => (
                    <ChannelsConfirmUnlinkDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/setting/channels");
                        }}
                    />
                )}
            </Route>
            <Route exact path="/setting/channel/:id/unlink">
                {({ history, match }) => (
                    <ChannelsConfirmUnlinkDialog
                        show={match != null}
                        onHide={() => {
                            history.push(`/setting/channel/${match.params.id}`);
                        }}
                    />
                )}
            </Route>
            <Route exact path="/setting/channels/sync-error/:id">
                {({ history, match }) => (
                    <ChannelsSyncErrorDialog
                        show={match != null}
                        onHide={() => {
                            history.push("/setting/channels");
                        }}
                    />
                )}
            </Route>
        </>
    );
}
