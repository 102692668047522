import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import queryString from 'querystring'
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';
import { useIntl } from 'react-intl';
import EditVertical from './EditVertical';
import LoadingDialog from '../../../ProductsStore/product-new/LoadingDialog';
import mutate_prvSaveSettingProviderConnected from '../../../../../graphql/mutate_prvSaveSettingProviderConnected';
import query_prvProviderConnectedDetail from '../../../../../graphql/query_prvProviderConnectedDetail';
import ModalConfirm from './ModalConfirm';

const TableSettingKiotViet = () => {
    const location = useLocation();
    const history = useHistory();
    const { addToast } = useToasts()
    const params = queryString.parse(location.search.slice(1, 100000));
    const {formatMessage} = useIntl()
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [show, setShow] = useState(false);
    const [pendingValue, setPendingValue] = useState(null);
    
    const {data: dataDetailProvider, loading: loadingDataProvider} = useQuery(query_prvProviderConnectedDetail, {
        variables: {
            id: +params?.id
        },
        fetchPolicy: 'cache-and-network',
        skip: !params?.id
    })
    const settings = useMemo(() => {
        if(dataDetailProvider?.prvProviderConnectedDetail) {
            return JSON.parse(dataDetailProvider?.prvProviderConnectedDetail?.settings)
        }
        return {}
    }, [dataDetailProvider])
    const [prvSaveSettingProviderConnected, {loading: loadingSaveSetting}] = useMutation(mutate_prvSaveSettingProviderConnected, {
        awaitRefetchQueries: true,
        refetchQueries: ['prvProviderConnectedDetail']
    })

    const handleConfirm = async () => {
        const newSettings = {
          ...settings,
          rate: +pendingValue,
        };
        let { data } = await prvSaveSettingProviderConnected({
          variables: {
            provider_connected_id: +dataDetailProvider?.prvProviderConnectedDetail?.id,
            setting: JSON.stringify(newSettings),
          },
        });
        if (data?.prvSaveSettingProviderConnected?.success) {
          addToast(formatMessage({ defaultMessage: "Cập nhật tỷ lệ đẩy thành công" }), { appearance: "success" });
        } else {
          addToast(data?.prvSaveSettingProviderConnected?.message || formatMessage({ defaultMessage: "Cập nhật tỷ lệ đẩy thất bại" }), { appearance: "error" });
        }
        setShowConfirmModal(false);
    };
    

    return (
        <>
            <LoadingDialog show={loadingSaveSetting || loadingDataProvider}/>
            {showConfirmModal && (
                <ModalConfirm
                    onHide={() => setShowConfirmModal(false)}
                    onSubmit={handleConfirm}
                />
            )}
            <Card>
                <CardHeader>
                    <div className='d-flex align-items-center'>
                        <strong style={{fontSize: 18}}>{formatMessage({defaultMessage: 'Thông tin kết nối'})}</strong>    
                    </div> 
                </CardHeader>
                <CardBody>
                    <div className='mb-2'>
                        <strong>{formatMessage({defaultMessage: 'Tên nhà cung cấp:'})}</strong>
                        <span className='ml-2'>{dataDetailProvider?.prvProviderConnectedDetail?.provider_name || '--'}</span>
                    </div>
                    <div>
                        <strong>{formatMessage({defaultMessage: 'Webhook URL:'})}</strong>
                        <span className='ml-2'>{dataDetailProvider?.prvProviderConnectedDetail?.link_webhook || '--'}</span>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <div className='d-flex align-items-center'>
                        <strong style={{fontSize: 18}}>{formatMessage({defaultMessage: 'Cấu hình đồng bộ tồn'})}</strong>    
                    </div> 
                </CardHeader>
                <CardBody>
                    <div>
                        <span className='mr-2'>{formatMessage({defaultMessage: 'Tỷ lệ đẩy tồn từ KiotViet sang UpS:'})}</span>
                        <EditVertical 
                        show={show}
                        setShow={setShow}
                        text={(!!settings?.rate || settings?.rate == 0) ? settings?.rate : 100} 
                        onConfirm={async (value, callback) => {
                            setShow(false)
                            setPendingValue(value);
                            setShowConfirmModal(true);
                        }}/>
                    </div>
                </CardBody>
            </Card>
        </>
    )
};

export default TableSettingKiotViet;