import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import getCustomToken from "../../../../utils/getCustomToken";
import queryString from 'querystring';

const IdentifyExtensionPage = () => {
    const user = useSelector((state) => state.auth.user);
    const jwtToken = localStorage.getItem('jwt');
    const refreshToken = localStorage.getItem('refresh_token') || '';
    let { source, redirect_uri, state } = queryString.parse(location.search.slice(1, location.search.length))
    useEffect(() => {
        if (jwtToken) {
            getCustomToken(token => {
                if (!!token) {
                    window.location.replace(`${redirect_uri}/verify-token?uid=${user?.id}&token=${jwtToken}&isSubUser=${!!user?.is_subuser}&refreshToken=${refreshToken}&customToken=${token}&state=${state}`);
                } else {
                    window.location.replace(`${redirect_uri}/verify-token?uid=${user?.id}&token=${jwtToken}&refreshToken=${refreshToken}&isSubUser=${!!user?.is_subuser}&state=${state}`);
                }
            });
        }
    }, [jwtToken, user, refreshToken]);

    return <LayoutSplashScreen />
};

export default IdentifyExtensionPage;
