import React, { useLayoutEffect } from 'react'
import { Card, CardBody } from '../../../../_metronic/_partials/controls';
import { Helmet } from 'react-helmet';
import { ArrowBackIos } from '@material-ui/icons';
import { useSubheader } from '../../../../_metronic/layout';
import { useIntl } from 'react-intl';
import Table from './Table';
import { useQuery } from '@apollo/client';
import query_sme_catalog_stores from '../../../../graphql/query_sme_catalog_stores';

const HistoryConnectProvider = () => {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useSubheader();
  useLayoutEffect(() => {
    setBreadcrumbs([
      {
        title: formatMessage({ defaultMessage: "Cài đặt" }),
      },
      {
        title: formatMessage({ defaultMessage: "Kết nối mở rộng" }),
      },
      {
        title: formatMessage({ defaultMessage: "KiotViet" }),
      },
      {
        title: formatMessage({ defaultMessage: "Lịch sử liên kết theo file" }),
      },
    ]);
  }, []);

  return (
    <>
      {/* <a
        href="/products/warehouse-bill/history?tab=actions"
        className="mb-5"
        style={{ display: "block", color: "#ff5629" }}
      >
        {" "}
        <ArrowBackIos />
        {formatMessage({ defaultMessage: "Quay lại kết nối mở rộng" })}
      </a> */}
      <Card>
        <Helmet
          titleTemplate={formatMessage({ defaultMessage: "Lịch sử liên kết theo file" }) + " - UpS"}
          defaultTitle={formatMessage({ defaultMessage: "Lịch sử liên kết theo file" }) + " - UpS"}
        >
          <meta name="description" content={formatMessage({ defaultMessage: "Lịch sử liên kết theo file" }) + "- UpS"} />
        </Helmet>

        <CardBody>
          <p className="text-info mb-2" style={{ fontStyle: 'italic', fontSize: 12, paddingLeft: 6 }}>{formatMessage({defaultMessage: 'Hệ thống chỉ lưu trữ dữ liệu file tải xuống trong vòng 90 ngày gần nhất.'})}</p>
          <Table/>
        </CardBody>
      </Card>
    </>
  );
}

export default HistoryConnectProvider