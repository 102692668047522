import gql from 'graphql-tag';

export default gql`
    mutation MyMutation(
        $url: String!
        $sme_brand_id: Int!
        $provider_connected_id: Int!
    ) {
        userCreateProductFromProviderByFile(
            provider_connected_id: $provider_connected_id
            sme_brand_id: $sme_brand_id
            url: $url
        ) {
            message
            success
            total
            errors {
                message
                sku
            }
        }
    }
`;
