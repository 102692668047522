import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "querystring";
import Pagination from "../../../../components/Pagination";
import { useIntl } from "react-intl";
import RowTable from "./RowTable";
import query_sme_product_variant_provider_link_by_file_history from "../../../../graphql/query_sme_product_variant_provider_link_by_file_history";
import dayjs from "dayjs";
const Table = () => {
  const params = queryString.parse(useLocation().search.slice(1, 100000));
  const { formatMessage } = useIntl()
  const [timePoll, setTimePoll] = useState(1000)
  const page = useMemo(() => {
    try {
      let _page = Number(params.page);
      if (!Number.isNaN(_page)) {
        return Math.max(1, _page);
      } else {
        return 1;
      }
    } catch (error) {
      return 1;
    }
  }, [params.page]);

  const limit = useMemo(() => {
    try {
      let _value = Number(params.limit);
      if (!Number.isNaN(_value)) {
        return Math.max(25, _value);
      } else {
        return 25;
      }
    } catch (error) {
      return 25;
    }
  }, [params.limit]);
  const { data, error, loading, refetch } = useQuery(
    query_sme_product_variant_provider_link_by_file_history,
    {
      variables: {
        limit,
        offset: (page - 1) * limit,
        order_by: {created_at: 'desc'},
        where: {
          created_at: {_gt: dayjs().subtract(90, 'day').format('YYYY/MM/DD')}
        }
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: timePoll
    }
  );
  useMemo(() => {
    const WAITING_STATUS = 3
    const status = data?.sme_product_variant_provider_link_by_file_history?.map(item => item.status) || []
    status?.includes(WAITING_STATUS) ? setTimePoll(1000) : setTimePoll(0)
  }, [data])

  let totalRecord = data?.sme_product_variant_provider_link_by_file_history_aggregate?.aggregate?.count || 0;
  let totalPage = Math.ceil(totalRecord / limit);

  return (
    <>
      <table className="table table-borderless table-vertical-center fixed">
        <thead
          style={{
            position: "sticky",
            top: 41,
            zIndex: 31,
            background: "#F3F6F9",
            fontWeight: "bold",
            fontSize: "14px",
            borderRight: "1px solid #d9d9d9",
            borderBottom: "1px solid #d9d9d9",
            borderLeft: "1px solid #d9d9d9",
            borderTop: "1px solid #d9d9d9",
          }}
        >
          <tr className="font-size-lg">
            <th style={{ fontSize: "14px" }} width="30%">
              <div className="d-flex">
                <span className="mx-4">{formatMessage({ defaultMessage: "Thời gian thực hiện liên kết theo file" })}</span>
              </div>
            </th>
            <th style={{ fontSize: "14px" }} width="25%">
              {formatMessage({ defaultMessage: "Tiến trình" })}
            </th>
            <th style={{ fontSize: "14px" }} width="25%">
              {formatMessage({ defaultMessage: "Kết quả liên kết" })}
            </th>
            <th style={{ fontSize: "14px", textAlign:'center' }} width="20%">
              {formatMessage({ defaultMessage: "Thao tác" })}
            </th>
          </tr>
        </thead>
        <tbody
          style={{
            borderLeft: "1px solid #d9d9d9",
            borderRight: "1px solid #d9d9d9",
          }}
        >
          {loading && (
            <div
              className="text-center w-100 mt-4"
              style={{ position: "absolute" }}
            >
              <span className="ml-3 spinner spinner-primary"></span>
            </div>
          )}
          {!!error && !loading && (
            <div
              className="w-100 text-center mt-8"
              style={{ position: "absolute" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <i
                  className="far fa-times-circle text-danger"
                  style={{ fontSize: 48, marginBottom: 8 }}
                ></i>
                <p className="mb-6">{formatMessage({ defaultMessage: "Xảy ra lỗi trong quá trình tải dữ liệu" })}</p>
                <button
                  className="btn btn-primary btn-elevate"
                  style={{ width: 100 }}
                  onClick={(e) => {
                    e.preventDefault();
                    refetch();
                  }}
                >
                  {formatMessage({ defaultMessage: "Tải lại" })}
                </button>
              </div>
            </div>
          )}
          <RowTable
            refetch={refetch}
            data={data?.sme_product_variant_provider_link_by_file_history || []}
          />
        </tbody>
      </table>
      {!error && (
        <Pagination
          page={page}
          totalPage={totalPage}
          loading={loading}
          limit={limit}
          totalRecord={totalRecord}
          count={data?.sme_product_variant_provider_link_by_file_history?.length}
          basePath={"/setting/history-connect-provider"}
          emptyTitle={formatMessage({ defaultMessage: "Không có dữ liệu" })}
        />
      )}
    </>
  );
};

export default Table
