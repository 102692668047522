import gql from 'graphql-tag';

export default gql`
    mutation linkProductFromProvider(
        $data: [LinkProductFromProviderData!]!
        $provider_connected_id: Int!
    ) {
        linkProductFromProvider(
            provider_connected_id: $provider_connected_id
            data: $data
        ) {
            message
            total
            success
            errors {
                message
                sku
                provider_sku
            }
        }
    }
`;
