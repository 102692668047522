import gql from 'graphql-tag';

export default gql`
    query cfGetAllTemplateExport($type: Int!) {
        cfGetAllTemplateExport(type: $type) {
            message
            success
            data {
                code
                config
                created_at
                is_private
                id
                name
                status
                type
                updated_at
            }
        }
    }
`;
