import gql from "graphql-tag";

export default gql`
  query userGetListWarehouseFullfillment($connectedProviderId: Int!, $fulfillmentBy: String) {
    userGetListWarehouseFullfillment(connectedProviderId: $connectedProviderId, fulfillmentBy: $fulfillmentBy) {
        data {
            code
            email
            fullAddress
            isActive
            name
            phoneNumber
            shortName
        }
        message
        success
    }
  }
`;

