import gql from 'graphql-tag';

export default gql`
    mutation cfUpdateTemplateStatus($status: Int!, $template_id: Int!) {
        cfUpdateTemplateStatus(status: $status, template_id: $template_id) {
            message
            success
        }
    }
`;
