import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import query_sme_catalog_stores from '../../../../../graphql/query_sme_catalog_stores';
import Select from 'react-select';
import PaginationModal from '../../../../../components/PaginationModal'
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Table from 'rc-table';
import query_sme_catalog_inventory_items_kiot_viet from '../../../../../graphql/query_sme_catalog_inventory_items_kiot_viet';
import dayjs from 'dayjs';

export const ModalInventoryStock = ({ show, onHide, item}) => {
    const { formatMessage } = useIntl();
    const [currentWarehouse, setCurrentWarehouse] = useState([])
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);    
    const { data: dataSmeWarehouse, loading: loadingDataSmeWarehouse } = useQuery(query_sme_catalog_stores, {
        variables: {
            where: {
                fulfillment_by: {
                    _eq: 3
                }
            }
        },
        fetchPolicy: 'cache-and-network'
    })

    let orderByInventories = {
        stock_actual: 'desc_nulls_last',
        variant_id: 'desc_nulls_last'
    }

    let whereConditionInventoryItems = useMemo(() => {
          return {
            variant_id: { _eq: item?.variant_id },
            sme_store: {
                fulfillment_by: {
                    _eq: 3
                }
            },
            ...(!!currentWarehouse?.length ? {
              sme_store_id: { _in: currentWarehouse?.map(item => +item) }
            } : {}),
          }
        }, [item, currentWarehouse]
    );
    const {data: dataInventoryStock, loading: loadingDataInventoryStock} = useQuery(query_sme_catalog_inventory_items_kiot_viet, {
        variables: {
            limit,
            offset: (page - 1) * limit,
            where: whereConditionInventoryItems,
            order_by: orderByInventories
          },
        fetchPolicy: 'no-cache',
    })

    const optionWarehouse = useMemo(() => {
        if (dataSmeWarehouse) {
            return dataSmeWarehouse.sme_warehouses.map(item => ({
                value: item.id,
                label: item.name
            }))
        }
        return []
    }, [dataSmeWarehouse])

    const columns = useMemo(() => {
        return [
            {
                title: formatMessage({ defaultMessage: 'Mã kho' }),
                align: 'left',
                width: 100,
                render: (record, item) => {
                    return (
                        <span>{record?.warehouse_code}</span>
                    )
                }
            },
            {
                title: formatMessage({ defaultMessage: 'Tên kho' }),
                align: 'left',
                width: 100,
                render: (record, item) => {
                    return (
                        <span>{record?.warehouse_name}</span>

                    )
                }
            },
            {
                title: formatMessage({ defaultMessage: 'Tồn thực tế' }),
                align: 'center',
                width: 100,
                render: (record, item) => {
                    return (
                        <span>{record?.stock_actual}</span>
                    )
                }
            }
        ]
    }, [])

    const dataTable = useMemo(() => {
        if (dataInventoryStock?.sme_catalog_inventory_items?.length) {
            return dataInventoryStock?.sme_catalog_inventory_items.map(item => {
                return {
                    key: item.id,
                    warehouse_name: item?.sme_store?.name,
                    warehouse_code: item?.sme_store?.code,
                    stock_actual: item.stock_actual,
                }
            })
        }
        return []
    }, [dataInventoryStock])
    return (
        <Modal
            onHide={onHide}
            show={show}
            aria-labelledby="example-modal-sizes-title-md"
            size='lg'
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {formatMessage({ defaultMessage: "Thông tin tồn kho" })}
                </Modal.Title>
                <span>
                    <i
                        style={{ cursor: "pointer" }}
                        onClick={onHide}
                        className="drawer-filter-icon fas fa-times icon-md text-right"
                    ></i>
                </span>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default text-center">
                <div className='row d-flex justify-content-between'>
                    <div className='col-4' style={{ textAlign: 'left' }}>
                        <span>{formatMessage({ defaultMessage: "Mã SKU: " })}</span>
                        <strong className='ml-2'>{item?.sku}</strong>
                    </div>
                    <div className='col-6' style={{ textAlign: 'right' }}>
                        <span>{formatMessage({ defaultMessage: "Thời gian cập nhật: " })}</span>
                        <span className='ml-2'>{item?.inventoryUpdateDate ? dayjs(item?.inventoryUpdateDate).format("HH:mm DD/MM/YYYY") : '--'}</span>
                    </div>
                </div>
                <div className='my-4 row d-flex justify-content-end'>
                    <div className='col-6'>
                        <Select
                            className='w-100 custom-select-warehouse'
                            value={optionWarehouse.filter(option => currentWarehouse?.includes(option.value))}
                            isClearable
                            isMulti
                            options={optionWarehouse}
                            placeholder={formatMessage({ defaultMessage: 'Chọn kho hàng' })}
                            onChange={values => {
                                if (values?.length > 0) {
                                    setCurrentWarehouse(values?.map(item => item?.value))
                                } else {
                                    setCurrentWarehouse([])
                                }
                            }}
                            formatOptionLabel={(option, labelMeta) => {
                                return <div>{option.label}</div>
                            }}
                        />
                    </div>
                </div>
                <div className='my-4'>
                    <Table
                        style={ loadingDataInventoryStock ? { opacity: 0.4 } : {}}
                        className="upbase-table"
                        columns={columns}
                        data={dataTable || []}
                        emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                            <img src={toAbsoluteUrl("/media/empty.png")} alt="" width={80} />
                            <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có thông tin tồn hàng hóa' })}</span>
                            </div>}
                        tableLayout="auto"
                    />
                </div>
                {!!(dataInventoryStock?.sme_catalog_inventory_items?.length) && <PaginationModal
                    page={page}
                    limit={limit}
                    onPanigate={(page) => setPage(page)}
                    totalPage={Math.ceil(dataInventoryStock?.sme_catalog_inventory_items_aggregate?.aggregate?.count / limit)}
                    totalRecord={dataInventoryStock?.sme_catalog_inventory_items_aggregate?.aggregate?.count || 0}
                    count={dataInventoryStock?.sme_catalog_inventory_items?.length}
                    onSizePage={(limit) => {
                        setPage(1);
                        setLimit(+limit);
                    }}
                    emptyTitle={formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}
                />}
            </Modal.Body>
        </Modal>
    );
};