import gql from 'graphql-tag';

export default gql`
    query cfGetConfigTemplateExport($template_id: Int!) {
        cfGetConfigTemplateExport(template_id: $template_id) {
            message
            success
            template_code
            template_config
            template_id
            template_name
            template_status
            template_type
            data {
                column_name
                created_at
                id
                position
                source_data
                template_id
                row_name
                type
                updated_at
                value
            }
        }
    }
`;
